export enum FeatureToggle {
  TerraAccountCreate = 'terra-account-create',
  TerraAccountDetails = 'terra-account_details',
  TerraAccountFutures = 'terra-account-futures',
  TerraAccountingIntegrationsBilly = 'terra-accounting_integrations-billy',
  TerraAccountingIntegrationsDinero = 'terra-accounting_integrations-dinero',
  TerraAccountingIntegrationsEAccounting = 'terra-accounting_integrations-eaccounting',
  TerraAccountingIntegrationsEconomic = 'terra-accounting_integrations-economic',
  TerraAccountingIntegrationsFortnox = 'terra-accounting_integrations-fortnox',
  TerraAccountMissingReceipts = 'terra-account-missing_receipts',
  TerraAccountReservations = 'terra-account-reservations',
  TerraAccountsView = 'terra-accounts_view',
  TerraAccountTransactions = 'terra-account-transactions',
  TerraCardOrder = 'terra-card-order',
  TerraCards = 'terra-cards',
  TerraCardDetails = 'terra-card-details',
  TerraDirectDebit = 'terra-direct_debit',
  TerraExportStatement = 'terra-export_statement',
  TerraHints = 'terra-hints',
  TerraInsightsBiggestMerchants = 'terra-insights-biggest_merchants',
  TerraInsightsCashFlowOverview = 'terra-insights-cash_flow_overview',
  TerraInsightsCashFlowOverviewChart = 'terra-insights-cash_flow_overview-chart',
  TerraInsurance = 'terra-insurance',
  TerraLatestTransactions = 'terra-latest_transactions',
  TerraLoans = 'terra-loans',
  TerraLoginPrivate = 'terra-login-private',
  TerraNemKonto = 'terra-nemkonto',
  TerraNemKontoManage = 'terra-nemkonto-manage',
  TerraPaymentsPayBillInvoice = 'terra-payments-pay_bill-invoice',
  TerraPaymentsPayBillPaymentSlip = 'terra-payments-pay_bill-payment_slip',
  TerraPaymentsPayBillScan = 'terra-payments-pay_bill-scan',
  TerraPaymentsStandingOrders = 'terra-payments-standing_orders',
  TerraPaymentsTransferDomestic = 'terra-payments-transfer-domestic',
  TerraPaymentsTransferInternational = 'terra-payments-transfer-international',
  TerraSettingsLanguage = 'terra-settings-language',
  TerraSettingsRegion = 'terra-settings-region',
  TerraSettingsTheme = 'terra-settings-theme',
  TerraSubscriptionTiers = 'terra-subscription_tiers',
  TerraUserSwitch = 'terra-user-switch',
  TerraAccountAutomation = 'terra-account-automation',
}

export type FeatureToggleType = `${FeatureToggle}`;

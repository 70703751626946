import { ComponentType, FC } from 'react';

import { FeatureToggleType } from './types';
import {
  useFeatureToggleAnyMatchLazy,
  useFeatureToggleLazy,
} from './use_feature_toggle';

export type WithFeatureToggleProps = (
  allowedFeatures: FeatureToggleType | Array<FeatureToggleType>
) => <T>(Component: ComponentType<T>) => FC<T>;

export const withFeatureToggleAnyMatch: WithFeatureToggleProps =
  (allowedFeature) => (Component) => (props) => {
    const featureToggle = useFeatureToggleLazy();
    const featureToggleAnyMatch = useFeatureToggleAnyMatchLazy();

    let isAuthorized: boolean;
    if (Array.isArray(allowedFeature)) {
      isAuthorized = featureToggleAnyMatch(allowedFeature);
    } else {
      isAuthorized = featureToggle(allowedFeature);
    }

    // TODO: Don't understand why any is needed here.
    return isAuthorized ? <Component {...(props as any)} /> : null;
  };

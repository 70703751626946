import { useCallback, useEffect } from 'react';
import { event as track } from 'nextjs-google-analytics';

export type CategoryActionMap = {
  app: 'app_load_app';
  account:
    | 'account_authorizations'
    | 'account_futures'
    | 'account_missing_attachment';
  export_statement: 'export_statement_export_transactions';
  payment_domestic:
    | 'payment_domestic_opened'
    | 'payment_domestic_initiated'
    | 'payment_domestic_initiated_with_scan'
    | 'payment_domestic_done'
    | 'payment_domestic_done_with_scan';
  pay_bill:
    | 'pay_bill_opened'
    | 'pay_bill_scan_initiated'
    | 'pay_bill_scan_successful'
    | 'pay_bill_initiated'
    | 'pay_bill_initiated_with_scan'
    | 'pay_bill_done'
    | 'pay_bill_done_with_scan';
  transaction:
    | 'transaction_attachment_upload'
    | 'transaction_attachment_download'
    | 'transaction_attachment_delete';
  cards: 'cards_show_details';
  loans:
    | 'loans_intro'
    | 'loans_onboard'
    | 'loans_crunching'
    | 'loans_preparing'
    | 'loans_onboarded'
    | 'loans_error_onboarding';
};

type EventOptions = typeof track extends (arg1: any, arg2: infer U) => any
  ? NonNullable<U>
  : never;

export const useGoogleAnalyticsEventLazy = () =>
  useCallback(
    <C extends keyof CategoryActionMap, A extends CategoryActionMap[C]>({
      category,
      action,
      label,
      value,
      nonInteraction,
    }: EventOptions & { category: C; action: A }) =>
      track(action, {
        category,
        label,
        value,
        nonInteraction,
      }),
    []
  );

export const useGoogleAnalyticsEvent = <
  C extends keyof CategoryActionMap,
  A extends CategoryActionMap[C],
>({
  category,
  action,
}: EventOptions & { category: C; action: A }) => {
  const triggerAnalyticsEvent = useGoogleAnalyticsEventLazy();

  useEffect(
    () =>
      triggerAnalyticsEvent({
        category: category as C,
        action: action as A,
      }),
    [action, category, triggerAnalyticsEvent]
  );
};

import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { setContext } from '@apollo/client/link/context';
import { signOut } from 'next-auth/react';

import introspectionResult from './__codegen__/introspection-result';

const isDev = process.env.NEXT_PUBLIC_VERCEL_ENVIRONMENT === 'development';
const isMock = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

const localeLink = setContext(async (_, { headers }) => {
  const locale = Cookies.get('NEXT_LOCALE') || 'en';
  await getSession();

  return {
    headers: {
      ...headers,
      'x-language': locale,
    },
  };
});

const getSession = async () => {
  const res = await fetch('/api/auth/session');
  const session = await res.json();
  if (
    !session ||
    Object.keys(session).length < 1 ||
    session?.error === 'RefreshAccessTokenError'
  ) {
    await signOut(); // Force sign in to hopefully resolve error
  }
};

export function createGraphqlStitcherClient() {
  const httpLink = new HttpLink({
    uri: '/api/graph/graphql',
    credentials: 'include',
  });

  return new ApolloClient({
    link: from([
      onError(({ networkError, operation, forward }) => {
        if (
          networkError &&
          'statusCode' in networkError &&
          networkError.statusCode === 401
        ) {
          // eslint-disable-next-line no-console
          console.log(
            `[Status code]: ${networkError.statusCode} - [Network error]: ${networkError}`
          );
        }

        return forward(operation);
      }),
      localeLink.concat(httpLink),
    ]),
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            directdebit_getMandate: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'directdebit_Mandate',
                  id: args?.mandateId,
                });
              },
            },
          },
        },
        premium_BenefitV2: {
          keyFields: false,
        },
        premium_BenefitsGroupSimple: {
          keyFields: false,
        },
        premium_BenefitsGroupImageWithDropdown: {
          keyFields: false,
        },
        premium_PlanDetails: {
          keyFields: ['planId'],
        },
        RelatedEntity: {
          fields: {
            residence: {
              merge: true,
            },
          },
        },
        KycProcess: {
          fields: {
            applicationState: {
              merge: true,
            },
            validation: {
              merge: true,
            },
          },
        },
      },
    }),
    connectToDevTools: isDev || isMock,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

import { useCallback, useMemo } from 'react';

import { useFeatureList } from '../ui/FeatureProvider';

import { FeatureToggleType } from './types';

export const useFeatureToggleAnyMatch = (
  featureToggles: Array<FeatureToggleType>
): boolean => {
  const featureList = useFeatureToggleLazy();

  return featureToggles.some((featureToggle) => featureList(featureToggle));
};

export const useFeatureToggleAnyMatchLazy = () => {
  const featureList = useFeatureToggleLazy();

  return useCallback(
    (featureToggles: Array<FeatureToggleType>) =>
      featureToggles.some((featureToggle) => featureList(featureToggle)),
    [featureList]
  );
};

export const useFeatureToggle = (featureToggle: FeatureToggleType): boolean => {
  const hasFeature = useFeatureToggleLazy();
  return useMemo(() => hasFeature(featureToggle), [hasFeature, featureToggle]);
};

export function useFeatureToggleLazy() {
  const { features, isLoading } = useFeatureList();

  return useCallback(
    (featureToggle: FeatureToggleType): boolean =>
      !isLoading &&
      !!features.find((f) => f.id === featureToggle && f.isEnabled),
    [features, isLoading]
  );
}

export const useFeatureToggleAllMatchLazy = () => {
  const featureList = useFeatureToggleLazy();

  return useCallback(
    (featureToggles: Array<FeatureToggleType>) =>
      featureToggles.every((featureToggle) => featureList(featureToggle)),
    [featureList]
  );
};

import {
  Button,
  DialogActions,
  DialogBase,
  Flex,
  Modal,
  Text,
  useModal,
} from '@lunarway/matter-web';
import { useTranslation } from 'next-i18next';
import styled, { css, useTheme } from 'styled-components';

import { useStorage } from '_src/app/storage/domain/use_storage';
import { QRCode } from '_src/framework/components/qr_code/QrCode';

type DownloadAppHintDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DownloadAppHintDialog = ({
  isOpen,
  onClose,
}: DownloadAppHintDialogProps) => {
  const { t } = useTranslation('hints');
  const { setItem } = useStorage('download_app-hint');
  const { modalProps, modalTriggerProps } = useModal();
  const theme = useTheme();

  const handleClose = () => {
    setItem('false');
    onClose();
  };

  return (
    <Modal
      {...modalProps}
      {...modalTriggerProps}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <StyledDialogBase>
        <Flex flexDirection="column" alignItems="center" gap={300}>
          <StyledQRCodeWrapper>
            <QRCode
              width={160}
              backgroundColor={theme.surfaceOverlayPrimary}
              link="https://www.lunar.app/en/dl?s=b&m=dk&ls=qr&wc=b2b-web-application-mobile-install-nudge"
            />
          </StyledQRCodeWrapper>
          <Flex flexDirection="column" gap={150}>
            <Flex flexDirection="column" gap={100}>
              <Text fontSize="titleM" textAlign="center">
                {t('hints.download_app.title')}
              </Text>
              <Text textAlign="center">
                {t('hints.download_app.description')}
              </Text>
            </Flex>
            <Text fontSize="small" textAlign="center" variant="secondary">
              {t('hints.download_app.disclosure')}
            </Text>
          </Flex>
          <DialogActions alignSelf="stretch" resetDefaultPadding>
            <Button variant="secondary" onClick={handleClose}>
              {t('hints.download_app.got_it')}
            </Button>
          </DialogActions>
        </Flex>
      </StyledDialogBase>
    </Modal>
  );
};

const StyledDialogBase = styled(DialogBase)(
  ({ theme }) => css`
    padding-block-start: ${theme.tokens.spaces[400]};
    padding-block-end: ${theme.tokens.spaces[300]};
    padding-inline: ${theme.tokens.spaces[300]};
    max-width: 360px;
  `
);

const StyledQRCodeWrapper = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.borderOpaque};
    border-radius: ${theme.tokens.radii[150]};
    padding: ${theme.tokens.spaces[100]};
  `
);

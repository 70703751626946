import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  Toast,
  ToastProps,
  ToastProvider,
  ToastViewport,
} from '@lunarway/matter-web';

import { useLogServerSideError } from '_src/contexts/log/logger';
import { LogLevel } from '_src/__codegen__/types';

type Success = Pick<ToastProps, 'title' | 'actionLabel' | 'onAction'>;
type Info = Pick<
  ToastProps,
  'title' | 'actionLabel' | 'onAction' | 'adornmentStart'
>;

interface StateProps {
  info: (options: Info) => void;
  success: (options: Success) => void;
  warn: (
    error?: unknown,
    options?: { title: string; reload?: boolean }
  ) => void;
}
export const DefaultNotifyContext = createContext<StateProps>({
  info: () => {},
  success: () => {},
  warn: () => {},
});

export function useNotify() {
  return useContext(DefaultNotifyContext);
}

type Notification = Pick<
  ToastProps,
  'title' | 'actionLabel' | 'onAction' | 'variant' | 'adornmentStart'
>;

interface NotifyProviderProps {
  children: ReactNode;
}
export const NotifyProvider = ({ children }: NotifyProviderProps) => {
  const [open, setOpen] = useState(false);
  const timerRef = useRef(0);
  const [notification, setNotification] = useState<undefined | Notification>(
    undefined
  );
  const log = useLogServerSideError();
  const router = useRouter();
  const { t } = useTranslation('notification');

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const api = useMemo(
    () => ({
      info(options: Info) {
        addNotification(options);
      },
      success(options: Success) {
        addNotification({ ...options, variant: 'success' });
      },
      warn(
        error?: unknown,
        options?: {
          title: string;
          reload?: boolean;
        }
      ) {
        log(LogLevel.Error, error);
        addNotification({
          actionLabel: options?.reload ? t('notification.action') : undefined,
          title: options?.title ?? t('notification.title'),
          variant: 'error',
          onAction: options?.reload ? () => router.reload() : undefined,
        });
      },
    }),
    [log, router, t]
  );

  const addNotification = (options: Notification) => {
    setOpen(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setNotification(options);
      setOpen(true);
    }, 250);
  };

  return (
    <DefaultNotifyContext.Provider value={api}>
      <ToastProvider>
        <Toast
          key={notification?.title}
          actionLabel={notification?.actionLabel}
          adornmentStart={notification?.adornmentStart}
          onAction={notification?.onAction}
          onOpenChange={setOpen}
          open={open}
          title={notification?.title ?? ''}
          variant={notification?.variant}
          data-test-id="toast-notification"
        />
        <ToastViewport />
        {children}
      </ToastProvider>
    </DefaultNotifyContext.Provider>
  );
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    premium_BenefitsGroup: [
      'premium_BenefitsGroupImageWithDropdown',
      'premium_BenefitsGroupSimple',
    ],
  },
};
export default result;

import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { DownloadAppHintPopover } from '_src/app/hints';

import { AppNavigation } from '../navigation/AppNavigation';

import { AppShellMode, useAppShellMode } from './useAppShellMode';

export type AppShellProps = {
  children: ReactNode;
};

export const AppShell = ({ children }: AppShellProps) => {
  const mode = useAppShellMode();

  return (
    <AppGrid $mode={mode} data-test-id="app-shell">
      <AppNavigation />
      <AppContent>
        {children}
        <DownloadAppHintPopover />
      </AppContent>
    </AppGrid>
  );
};

const AppGrid = styled.div<{ $mode: AppShellMode }>(
  ({ $mode }) => css`
    display: grid;
    min-height: 100vh;

    ${$mode === 'large' &&
    css`
      grid-template-columns: auto 1fr;
      grid-template-rows: auto;
    `}

    ${$mode === 'small' &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    `}
  `
);

const AppContent = styled.main(
  () => css`
    width: 100%;
  `
);

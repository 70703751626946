type PkceChallenge = {
  code_verifier: string;
  code_challenge: string;
};

export const createChallenge = async (): Promise<PkceChallenge> => {
  const verifier = generateRandomString(128);
  const challenge = await generateCodeChallenge(verifier);

  return {
    code_verifier: verifier,
    code_challenge: challenge,
  };
};
const generateRandomString = (length: number): string => {
  const bytes = new Uint8Array(length);
  crypto.getRandomValues(bytes);
  return Array.from(bytes)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);

  const base64Encoded = arrayBufferToBase64(digest);

  return base64Encoded
    .replaceAll(/=+$/g, '')
    .replaceAll('+', '-')
    .replaceAll('/', '_');
};

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

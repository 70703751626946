import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type UserFeatureListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type UserFeatureListQuery = {
  readonly __typename?: 'Query';
  readonly features: ReadonlyArray<{
    readonly __typename?: 'FeatureEnabled';
    readonly id: string;
    readonly isEnabled: boolean;
  }>;
};

export type FeatureFragment = {
  readonly __typename?: 'FeatureEnabled';
  readonly id: string;
  readonly isEnabled: boolean;
};

export const FeatureFragmentDoc = gql`
  fragment Feature on FeatureEnabled {
    id
    isEnabled
  }
`;
export const UserFeatureListDocument = gql`
  query UserFeatureList {
    features: terraGetUserFeatures {
      ...Feature
    }
  }
  ${FeatureFragmentDoc}
`;

/**
 * __useUserFeatureListQuery__
 *
 * To run a query within a React component, call `useUserFeatureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeatureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeatureListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFeatureListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFeatureListQuery, UserFeatureListQueryVariables>(
    UserFeatureListDocument,
    options
  );
}
export function useUserFeatureListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >(UserFeatureListDocument, options);
}
export function useUserFeatureListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >(UserFeatureListDocument, options);
}
export type UserFeatureListQueryHookResult = ReturnType<
  typeof useUserFeatureListQuery
>;
export type UserFeatureListLazyQueryHookResult = ReturnType<
  typeof useUserFeatureListLazyQuery
>;
export type UserFeatureListSuspenseQueryHookResult = ReturnType<
  typeof useUserFeatureListSuspenseQuery
>;
export type UserFeatureListQueryResult = Apollo.QueryResult<
  UserFeatureListQuery,
  UserFeatureListQueryVariables
>;
export const namedOperations = {
  Query: {
    UserFeatureList: 'UserFeatureList',
  },
  Fragment: {
    Feature: 'Feature',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUserFeatureListQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { terraGetUserFeatures }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUserFeatureListQuery = (
  resolver: GraphQLResponseResolver<
    UserFeatureListQuery,
    UserFeatureListQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<UserFeatureListQuery, UserFeatureListQueryVariables>(
    'UserFeatureList',
    resolver,
    options
  );

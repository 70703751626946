import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type GetAvatarQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;

export type GetAvatarQuery = {
  readonly __typename?: 'Query';
  readonly avatar: {
    readonly __typename?: 'UserAvatar';
    readonly avatarUrl: string;
  };
};

export const GetAvatarDocument = gql`
  query GetAvatar($userId: String!) {
    avatar: terraGetAvatar(userId: $userId) {
      avatarUrl
    }
  }
`;

/**
 * __useGetAvatarQuery__
 *
 * To run a query within a React component, call `useGetAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAvatarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvatarQuery,
    GetAvatarQueryVariables
  > &
    ({ variables: GetAvatarQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvatarQuery, GetAvatarQueryVariables>(
    GetAvatarDocument,
    options
  );
}
export function useGetAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvatarQuery,
    GetAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAvatarQuery, GetAvatarQueryVariables>(
    GetAvatarDocument,
    options
  );
}
export function useGetAvatarSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAvatarQuery,
    GetAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAvatarQuery, GetAvatarQueryVariables>(
    GetAvatarDocument,
    options
  );
}
export type GetAvatarQueryHookResult = ReturnType<typeof useGetAvatarQuery>;
export type GetAvatarLazyQueryHookResult = ReturnType<
  typeof useGetAvatarLazyQuery
>;
export type GetAvatarSuspenseQueryHookResult = ReturnType<
  typeof useGetAvatarSuspenseQuery
>;
export type GetAvatarQueryResult = Apollo.QueryResult<
  GetAvatarQuery,
  GetAvatarQueryVariables
>;
export const namedOperations = {
  Query: {
    GetAvatar: 'GetAvatar',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAvatarQuery(
 *   ({ query, variables }) => {
 *     const { userId } = variables;
 *     return HttpResponse.json({
 *       data: { terraGetAvatar }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetAvatarQuery = (
  resolver: GraphQLResponseResolver<GetAvatarQuery, GetAvatarQueryVariables>,
  options?: RequestHandlerOptions
) =>
  graphql.query<GetAvatarQuery, GetAvatarQueryVariables>(
    'GetAvatar',
    resolver,
    options
  );

import { Flex, MenuItem, MenuList, Text } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { RelatedUser } from '../data/useGetRelatedUsers';
import { useSwitchUser } from '../data/useSwitchUser';

import { UserSwitchAvatar } from './UserSwitchAvatar';

interface UserSwitchMenuItemProps {
  label: string;
  users: Array<RelatedUser>;
}

export const UserSwitchMenuItem = ({
  label,
  users,
}: UserSwitchMenuItemProps) => {
  const { t } = useTranslation('user_switch');
  const { data } = useSession();
  const switchUser = useSwitchUser();

  const getLoggedInUser = (userId: string) =>
    users.find((user) => user.user_relation_id === userId);

  const loggedInUser = data ? getLoggedInUser(data.user.id) : null;
  const filteredUsers = users.filter(
    (user) => user.user_relation_id !== loggedInUser?.user_relation_id
  );

  return (
    <StyledFlex flexDirection="column" gap={100}>
      <StyledLabel fontSize="small" variant="secondary" fontWeight="medium">
        {label}
      </StyledLabel>
      <Flex flexDirection="column">
        {filteredUsers.map((user) => (
          <StyledMenuList
            key={user.user_relation_id}
            aria-label="User Switch Menu"
            onAction={() => switchUser(user.user_relation_id)}
          >
            <MenuItem key={user.user_relation_id}>
              <Flex gap={100}>
                <UserSwitchAvatar user={user} size={300} />
                <StyledText>
                  {displayName(user, t('user_switch.unknown'))}
                </StyledText>
              </Flex>
            </MenuItem>
          </StyledMenuList>
        ))}
      </Flex>
    </StyledFlex>
  );
};

const displayName = (user: RelatedUser, fallback: string) => {
  if (user.organisation_name) {
    return user.organisation_name;
  }
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return fallback;
};

const StyledText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledLabel = styled(Text)(
  ({ theme }) => css`
    padding-inline-start: ${theme.tokens.spaces[100]};
    padding-block-start: ${theme.tokens.spaces[100]};
  `
);

const StyledFlex = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.tokens.spaces[100]};
    margin-inline: ${theme.tokens.spaces[100]};
    margin-block: -${theme.tokens.spaces[50]};
    border-radius: ${theme.tokens.radii[100]};
    background-color: ${theme.backgroundSecondary};
  `
);

const StyledMenuList = styled(MenuList)`
  padding: 0;
`;

import { useEffect, useRef } from 'react';

export type UseRequestAnimationFrameOptions = {
  enabled?: boolean;
};

export const useRequestAnimationFrame = (
  callback: (update: () => void) => void
) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const next = () => requestAnimationFrame(() => savedCallback.current(next));

    const raf = next();

    return () => {
      cancelAnimationFrame(raf);
    };
  }, []);
};

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  AvatarProps,
  Text,
} from '@lunarway/matter-web';
import React from 'react';

import { useGetAvatarQuery } from '_src/app/user_switch/data/__codegen__/avatar';
import { RelatedUser } from '_src/app/user_switch/data/useGetRelatedUsers';

export interface UserSwitchAvatarProps {
  user: RelatedUser;
  size?: AvatarProps['size'];
}
export const UserSwitchAvatar = ({
  user,
  size = 500,
}: UserSwitchAvatarProps) => {
  const initials = getInitials(user);
  const { data } = useGetAvatarQuery({
    variables: {
      userId: user.user_relation_id,
    },
  });

  return (
    <Avatar size={size} variant="round">
      <AvatarImage src={data?.avatar.avatarUrl} />
      <AvatarFallback asChild delayMs={500}>
        <Text fontWeight="medium" variant="secondary">
          {initials}
        </Text>
      </AvatarFallback>
    </Avatar>
  );
};

const getInitials = (user: RelatedUser) => {
  try {
    return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
  } catch (err) {
    return 'N/A';
  }
};

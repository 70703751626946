import { ReactNode, useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';

import { useMeQuery } from '_src/contexts/log/sentry/data/__codegen__/Me';
import { useLogServerSideError } from '_src/contexts/log/logger';
import { LogLevel } from '_src/__codegen__/types';

interface SentryContextProviderProps {
  children: ReactNode;
}

export const SentryContextProvider = ({
  children,
}: SentryContextProviderProps) => {
  const { data, loading } = useMeQuery();
  const log = useLogServerSideError();

  useMemo(async () => {
    if (!loading && data != null) {
      // generate unique id for session and post mapping to log in humio. As to not log user specific data in sentry
      const sentryUserSessionId = crypto.randomUUID();
      await log(
        LogLevel.Info,
        `Sentry sessionMapping userId: ${data.me.id} SentrySessionId: ${sentryUserSessionId}`
      );

      Sentry.setUser({
        id: sentryUserSessionId,
      });
    }
  }, [log, data, loading]);

  return children;
};

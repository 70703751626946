import { ReactElement } from 'react';
import { Flex, Text, focusRingWithBorder } from '@lunarway/matter-web';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export type AppNavigationLinkProps = {
  icon: ReactElement;
  activeIcon: ReactElement;
  href: string;
  label: string;
  'data-test-id': string;
  isNested: boolean;
  isActive: boolean;
};

export const AppNavigationLink = ({
  icon,
  activeIcon,
  label,
  href,
  'data-test-id': dataTestId,
  isNested,
  isActive,
}: AppNavigationLinkProps) => {
  let iconVisible = null;

  if (!isNested) {
    iconVisible = isActive ? activeIcon : icon;
  }

  return (
    <StyledAppNavigationLink
      asChild
      gap={100}
      alignItems="center"
      $isActive={isActive}
      $isNested={isNested}
      data-test-id={dataTestId}
    >
      <Link href={href}>
        {iconVisible}
        <StyledText whiteSpace="nowrap">{label}</StyledText>
      </Link>
    </StyledAppNavigationLink>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAppNavigationLink = styled(Flex)<{
  $isActive?: boolean;
  $isNested?: boolean;
}>(
  ({ theme, $isActive, $isNested }) => css`
    transition:
      background-color 150ms linear,
      transform 150ms cubic-bezier(0.65, 0.05, 0.36, 1);
    border: 1px solid transparent;
    height: 42px;
    border-radius: ${theme.tokens.radii[150]};
    padding: ${theme.tokens.spaces[100]};
    cursor: pointer;

    ${$isActive &&
    css`
      background-color: ${theme.interaction.selected};
    `};

    ${$isNested &&
    css`
      margin-inline-start: ${theme.tokens.spaces[400]};
    `};

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${theme.interaction.hovered};
        transform: translateY(-1px);
      }
    }

    &:focus-visible {
      ${focusRingWithBorder(theme.surfacePrimary)}
    }
  `
);

import { FocusScope, usePreventScroll } from 'react-aria';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export type DrawerProps = {
  top?: number;
  isOpen?: boolean;
  width?: 'full' | number;
  children: ReactNode;
};

export const Drawer = ({
  children,
  isOpen = false,
  width,
  top = 0,
}: DrawerProps) => {
  usePreventScroll({ isDisabled: !isOpen });

  if (!isOpen) {
    return null;
  }

  return (
    <FocusScope contain autoFocus>
      <StyledDrawer $top={top} $width={width}>
        {children}
      </StyledDrawer>
    </FocusScope>
  );
};

const StyledDrawer = styled.div<{ $top: number; $width?: 'full' | number }>(
  ({ theme, $top, $width }) => css`
    position: fixed;
    top: ${$top}px;
    left: 0;
    overflow: auto;
    z-index: 10;
    height: calc(100vh - ${$top}px);
    box-sizing: border-box;
    max-width: 100vw;
    background: ${theme.backgroundPrimary};

    ${$width === 'full' &&
    css`
      width: 100vw;
    `}

    ${$width !== 'full' &&
    css`
      width: ${$width}px;
    `}
  `
);

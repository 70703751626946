import { useCallback } from 'react';

import { useLoggerMutation } from '_src//contexts/log/data/__codegen__/Logger';
import { LogLevel } from '_src/__codegen__/types';

export function useLogServerSideError() {
  const [logStatementMutation] = useLoggerMutation();

  return useCallback(
    async (level: LogLevel, error?: unknown) => {
      const message = getErrorMessage(error);

      if (message) {
        await logStatementMutation({
          variables: {
            input: {
              level,
              message,
            },
          },
        });
      }
    },
    [logStatementMutation]
  );
}

const errorHasMessage = (obj: unknown): obj is Error =>
  (obj as Error).message !== undefined;

const getErrorMessage = (error: unknown): string | undefined => {
  if (error && errorHasMessage(error)) {
    return error.message;
  }

  if (error && typeof error === 'string') {
    return error;
  }

  return undefined;
};

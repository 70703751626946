import { useEffect, useState } from 'react';
import { signOut } from 'next-auth/react';

import { useFeatureList, useFeatureToggle } from '_src/app/feature_toggle';

export type RelatedUser = {
  agreements: Array<string>;
  user_relation_id: string;
  identity_id: string;
  relation_type: string;
  first_name: string;
  last_name: string;
  organisation_id: string;
  organisation_name: string;
  organisation_number: string;
  state: string;
};

export const useGetRelatedUsers = () => {
  const canLoginPrivate = useFeatureToggle('terra-login-private');
  const { isLoading } = useFeatureList();
  const [data, setData] = useState<Array<RelatedUser>>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  const privateRelationTypes: { [key: string]: boolean } = {
    dklb: true,
    dknb: true,
    dksb: true,
    selb: true,
    sepp: true,
    nolb: true,
    nopp: true,
    privateparty: true,
  };

  const fetchUsers = async () => {
    setError(null);
    setLoading(true);
    await getSession();

    const response = await fetch('/api/mediator');

    if (!response.ok) {
      setError(new Error('Failed to fetch users'));
      setLoading(false);
      return;
    }

    const { related } = await response.json();

    const privateFilter = canLoginPrivate
      ? related
      : related.filter(
          (rel: RelatedUser) => !privateRelationTypes[rel.relation_type]
        );

    const typeFilter = privateFilter.filter(
      (r: RelatedUser) => r.relation_type !== 'coworker' && r.state !== 'signup'
    );

    const agreementFilter = typeFilter.filter(
      (r: RelatedUser) =>
        r.agreements.length > 0 || privateRelationTypes[r.relation_type]
    );

    setData(agreementFilter);
    setLoading(isLoading);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, canLoginPrivate]);

  const users = data;
  return { users, error, loading };
};

const getSession = async () => {
  const res = await fetch('/api/auth/session');
  const session = await res.json();
  if (
    !session ||
    Object.keys(session).length < 1 ||
    session?.error === 'RefreshAccessTokenError'
  ) {
    await signOut(); // Force sign in to hopefully resolve error
  }
};

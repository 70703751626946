import * as Sentry from '@sentry/nextjs';

import {
  GetStorageItemDocument,
  GetStorageItemQuery,
  useGetStorageItemQuery,
  useSetStorageItemMutation,
} from '../data/__codegen__/Storage';

// ID naming convention: [purpose_name]-[optional_feature_name]-hint
type StorageItemId = 'download_app-hint';

export const useStorage = (id: StorageItemId) => {
  const { data, loading } = useGetStorageItemQuery({
    variables: { id },
    onError: (error) => {
      Sentry.captureException(
        new Error(`Error fetching item for id ${id}: ${error.message}`)
      );
    },
  });

  // https://www.apollographql.com/blog/demystifying-cache-normalization#additions
  const [setStorageItemMutation] = useSetStorageItemMutation({
    update: (cache, { data: mutationData }, { variables }) => {
      const newStorageItem = mutationData?.response;
      const existingStorageItem = cache.readQuery<GetStorageItemQuery>({
        query: GetStorageItemDocument,
        id: variables?.id,
      });

      if (newStorageItem && !existingStorageItem) {
        cache.writeQuery({
          query: GetStorageItemDocument,
          data: {
            response: {
              ...newStorageItem,
            },
          },
          variables,
        });
      }
    },
    onError: (error) => {
      Sentry.captureException(
        new Error(`Error setting item for id ${id}: ${error.message}`)
      );
    },
  });

  const setItem = async (value: string) => {
    await setStorageItemMutation({
      variables: { id, value },
    });
  };

  return {
    item: data?.response?.value,
    setItem,
    loading,
  };
};

import { useEffect } from 'react';

import { useNotify } from './notify_context';

export const useWarnOnError = (errorThrown?: unknown) => {
  const { warn } = useNotify();

  useEffect(() => {
    if (errorThrown) {
      warn(errorThrown);
    }
  }, [errorThrown, warn]);
};

import { TrigramIcon, CloseIcon, Button } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export type AppNavigationButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

export const AppNavigationButton = ({
  isOpen = false,
  onClick,
}: AppNavigationButtonProps) => (
  <StyledButton
    isAction
    variant="tertiary"
    adornmentStart={isOpen ? <CloseIcon /> : <TrigramIcon />}
    onClick={onClick}
  />
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    margin-inline-end: -${theme.tokens.spaces[100]};
  `
);

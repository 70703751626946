import { Button, ButtonGroup, Dialog, Flex, Text } from '@lunarway/matter-web';
import { useTranslation } from 'next-i18next';

import { Countdown } from '_src/framework/components/countdown/Countdown';

export type SessionTimeoutPromptProps = {
  isOpen: boolean;
  promptBeforeLogout: number;
  onSessionEnd: () => void;
  onSessionReset: () => void;
};

export const SessionTimeoutPrompt = ({
  isOpen,
  promptBeforeLogout,
  onSessionReset,
  onSessionEnd,
}: SessionTimeoutPromptProps) => {
  const { t } = useTranslation('notification');

  return (
    <Dialog isOpen={isOpen} title="">
      <Flex gap={300} flexDirection="column">
        <Flex gap={300} flexDirection="column">
          <Countdown duration={promptBeforeLogout} />
          <Flex gap={100} alignItems="center" flexDirection="column">
            <Text fontSize="titleM" fontWeight="medium">
              {t('notification.session.title')}
            </Text>
            <Text variant="secondary">
              {t('notification.session.description')}
            </Text>
          </Flex>
        </Flex>
        <ButtonGroup justify="even">
          <Button size="small" variant="primary" onClick={onSessionReset}>
            {t('notification.session.actions.reset_session')}
          </Button>
          <Button size="small" variant="secondary" onClick={onSessionEnd}>
            {t('notification.session.actions.end_session')}
          </Button>
        </ButtonGroup>
      </Flex>
    </Dialog>
  );
};

import { forwardRef } from 'react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Flex,
  FlexProps,
  Text,
  focusRingWithBorder,
} from '@lunarway/matter-web';
import styled, { css, useTheme } from 'styled-components';

import { UserFragment } from '_src/app/profile_menu/data/__codegen__/GetCurrentUser';

interface ProfileMenuButtonProps extends FlexProps {
  user?: UserFragment;
  isOpen?: boolean;
}

export const ProfileMenuButton = forwardRef<
  HTMLDivElement,
  ProfileMenuButtonProps
>(({ user, isOpen, ...otherProps }, forwardedRef) => {
  const theme = useTheme();
  const shortName = user?.ShortName.slice(0, 2).toLocaleUpperCase() || '';

  return (
    <StyledProfilePreviewButton
      ref={forwardedRef}
      $isOpen={isOpen}
      gap={100}
      display="inline-flex"
      alignItems="center"
      tabIndex={0}
      {...otherProps}
    >
      <StyledProfile alignItems="center" gap={100} style={{ minWidth: 0 }}>
        <Avatar size={400} variant="round" color={theme.surfaceTertiary}>
          <AvatarImage src={user?.AvatarURL} />
          <AvatarFallback asChild>
            <Text fontWeight="medium" variant="secondary">
              {shortName}
            </Text>
          </AvatarFallback>
        </Avatar>
        <StyledName fontWeight="medium">{user?.ShortName}</StyledName>
      </StyledProfile>
    </StyledProfilePreviewButton>
  );
});

const StyledProfile = styled(Flex)(
  () => css`
    min-width: 0;
  `
);

const StyledName = styled(Text)(
  () => css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
);

const StyledProfilePreviewButton = styled(Flex)<{
  $isOpen?: boolean;
}>(
  ({ theme, $isOpen }) => css`
    border: 1px solid transparent;
    padding: ${theme.tokens.spaces[100]};
    border-radius: ${theme.tokens.radii[150]};
    width: 100%;
    min-width: 0;
    cursor: pointer;
    justify-content: space-between;
    margin-top: auto;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${theme.interaction.hovered};
        border: 1px solid transparent;
      }
    }

    &:focus-visible {
      ${focusRingWithBorder(theme.surfacePrimary)}
    }

    ${$isOpen &&
    css`
      ${focusRingWithBorder(theme.surfacePrimary)}

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: ${theme.interaction.hovered};
          ${focusRingWithBorder(theme.surfacePrimary)}
        }
      }
    `}
  `
);

import { forwardRef } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  AppNavigationItem,
  useAppNavigationItems,
} from '_src/app/app_shell/ui/navigation/useAppNavigationItems';
import { LunarLogo } from '_src/framework/components/lunar_logo/LunarLogo';

export const AppNavigationLogo = forwardRef<HTMLDivElement>(
  ({ ...otherProps }, ref) => {
    const navItems = useAppNavigationItems();
    const [defaultRoute] = navItems.filter(
      (item): item is AppNavigationItem => 'route' in item
    );

    return (
      <StyledAppNavigationLogo href={defaultRoute.route} tabIndex={-1}>
        <StyledLunarLogo ref={ref} {...otherProps}>
          <LunarLogo />
        </StyledLunarLogo>
      </StyledAppNavigationLogo>
    );
  }
);

const StyledAppNavigationLogo = styled(Link)(
  () => css`
    display: flex;
    align-items: center;
  `
);

const StyledLunarLogo = styled.div(
  ({ theme }) => css`
    color: ${theme.textPrimary};
    display: block;
    padding-inline-start: ${theme.tokens.spaces[100]};

    svg {
      height: 24px;
    }
  `
);

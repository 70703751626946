import { Flex, Text } from '@lunarway/matter-web';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

export interface ProfileMenuItemProps {
  icon: ReactElement;
  label: string;
}

export const ProfileMenuItem = ({
  icon,
  label,
  ...otherProps
}: ProfileMenuItemProps) => (
  <StyledItem gap={100} alignItems="center" {...otherProps}>
    {icon}
    <Text fontSize="small" fontWeight="medium">
      {label}
    </Text>
  </StyledItem>
);

const StyledItem = styled(Flex)(
  ({ theme }) => css`
    padding-inline-end: ${theme.tokens.spaces[100]};
  `
);

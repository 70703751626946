import { useState } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { Flex } from '@lunarway/matter-web';

import { AppNavigationLink } from './AppNavigationLink';
import {
  AppNavigationCategory as AppNavigationCategoryType,
  AppNavigationItem,
  useAppNavigationItems,
} from './useAppNavigationItems';
import { AppNavigationCategory } from './AppNavigationCategory';

export const AppNavigationList = () => {
  const router = useRouter();
  const items = useAppNavigationItems();
  const [expanded, setExpanded] = useState<string | undefined>(
    findDefaultExpanded(router, items)
  );
  const handleClick = (id: string) => {
    setExpanded(expanded === id ? undefined : id);
  };

  return (
    <Flex
      asChild
      flexDirection="column"
      gap={100}
      data-test-id="app-navigation-list"
    >
      <nav>
        {items.map((item) =>
          'children' in item ? (
            <AppNavigationCategory
              id={item.id}
              key={item.id}
              label={item.title}
              icon={item.icon}
              isOpen={expanded === item.id}
              data-test-id={item.id}
              onClick={handleClick}
            >
              {item.children.map((child) => (
                <AppNavigationLink
                  key={child.id}
                  data-test-id={child.id}
                  icon={child.icon}
                  activeIcon={child.activeIcon}
                  href={child.route}
                  label={child.title}
                  isNested
                  isActive={child.pattern.test(router.pathname)}
                />
              ))}
            </AppNavigationCategory>
          ) : (
            <AppNavigationLink
              key={item.id}
              data-test-id={item.id}
              icon={item.icon}
              activeIcon={item.activeIcon}
              href={item.route}
              label={item.title}
              isNested={false}
              isActive={item.pattern.test(router.pathname)}
            />
          )
        )}
      </nav>
    </Flex>
  );
};

const findDefaultExpanded = (
  router: NextRouter,
  items: Array<AppNavigationItem | AppNavigationCategoryType>
) =>
  items.find(
    (item) =>
      'children' in item &&
      item.children.some((child) => child.pattern.test(router.pathname))
  )?.id;

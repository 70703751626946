import { useQRCode } from 'next-qrcode';
import { useTheme } from 'styled-components';

export type QrCodeProps = {
  link: string;
  width: number;
  strokeColor?: string;
  backgroundColor?: string;
};

export const QRCode = ({
  link,
  width,
  strokeColor,
  backgroundColor,
}: QrCodeProps) => {
  const theme = useTheme();
  const { SVG } = useQRCode();

  return (
    <SVG
      text={link}
      options={{
        width,
        margin: 1,
        scale: 4,
        color: {
          dark: strokeColor ?? theme.textPrimary,
          light: backgroundColor ?? theme.backgroundPrimary,
        },
      }}
    />
  );
};

import styled from 'styled-components';

import { useCountdown, UseCountdownOptions } from './useCountdown';

export type CountdownProps = UseCountdownOptions & {
  girth?: number;
  radius?: number;
};

export const Countdown = ({
  girth = 5,
  radius: radiusProp = 65,
  duration,
}: CountdownProps) => {
  const { readable, progress } = useCountdown({
    duration: duration * 1000,
  });

  const radius = radiusProp - girth * 2;
  const diameter = radius * 2 + girth;
  const center = diameter / 2;
  const circumference = radius * Math.PI * 2;
  const arc = (circumference * (100 - progress * 100)) / 100;

  return (
    <StyledCountdown>
      <StyledNumber>{readable}</StyledNumber>
      <StyledSVG height={diameter} width={diameter}>
        <StyledCircle
          r={radius}
          cx={center}
          cy={center}
          fill="none"
          strokeWidth={girth}
          strokeLinecap="round"
        />
        <StyledCircleProgress
          r={radius}
          cx={center}
          cy={center}
          fill="none"
          strokeWidth={girth}
          strokeLinecap="round"
          strokeDasharray={`${arc},${circumference}`}
        />
      </StyledSVG>
    </StyledCountdown>
  );
};

const StyledCountdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: visible;
`;

const StyledNumber = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  background-color: transparent;
  font-weight: 700;
  font-size: 26px;
`;

const StyledSVG = styled.svg`
  transform: rotateY(-360deg) rotateZ(-90deg);
`;

const StyledCircle = styled.circle`
  stroke: ${({ theme }) => theme.surfaceSecondary};
`;

const StyledCircleProgress = styled.circle`
  stroke: ${({ theme }) => theme.surfacePrimary};
`;

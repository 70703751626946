import { useRef, useState } from 'react';
import { PresenceType, useIdleTimer } from 'react-idle-timer';
import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { SessionTimeoutPrompt } from '_src/app/idle_session_timeout/SessionTimeoutPrompt';
import { useRequestAnimationFrame } from '_src/framework/utils/use_request_animation_frame';

export const timeout = 1000 * 10 * 60;
export const promptBeforeIdle = 1000 * 3 * 60;

export const IdleSessionTimeout = () => {
  const { t } = useTranslation('notification');
  const originalTitle = useRef(document.title);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const isMock = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

  const onPresenceChange = (presence: PresenceType) => {
    if (presence.type === 'active') {
      setIsPromptOpen(presence.prompted);
    }

    if (presence.type === 'idle') {
      signOut();
    }
  };

  const idleTimer = useIdleTimer({
    promptBeforeIdle,
    timeout,
    onPresenceChange,
    disabled: isMock,
  });

  const onResetSession = () => {
    idleTimer.reset();
    document.title = originalTitle.current;
    setIsPromptOpen(false);
  };

  useRequestAnimationFrame((update) => {
    if (isPromptOpen) {
      document.title = `${t('notification.session.idle', {
        time: Math.round(idleTimer.getRemainingTime() / 1000),
      })}`;
    }

    update();
  });

  if (!isPromptOpen) {
    return null;
  }

  return (
    <SessionTimeoutPrompt
      isOpen
      promptBeforeLogout={promptBeforeIdle / 1000}
      onSessionEnd={signOut}
      onSessionReset={onResetSession}
    />
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Flex } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

import { Drawer } from '_src/framework/components/drawer/Drawer';
import { ProfileMenu } from '_src/app/profile_menu/ui/ProfileMenu';

import { useAppShellMode } from '../shell/useAppShellMode';

import { AppNavigationLogo } from './AppNavigationLogo';
import { AppNavigationButton } from './AppNavigationButton';
import { AppNavigationList } from './AppNavigationList';
import { AppNavigationBar } from './AppNavigationBar';

const HEIGHT = 64;

export const AppNavigation = () => {
  const mode = useAppShellMode();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => setIsOpen((prev) => !prev), []);

  const hideNavigation = router.pathname === '/';

  useEffect(() => {
    const handleClose = () => setIsOpen(false);

    router.events.on('routeChangeComplete', handleClose);

    return () => {
      router.events.off('routeChangeComplete', handleClose);
    };
  }, [router]);

  if (hideNavigation) return null;

  if (mode === 'small') {
    return (
      <AppNavigationBar height={HEIGHT}>
        <AppNavigationLogo />
        <AppNavigationButton isOpen={isOpen} onClick={handleToggle} />
        <Drawer top={HEIGHT} width="full" isOpen={isOpen}>
          <StyledAppNavigationSmall
            gap={600}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <AppNavigationList />
            <ProfileMenu />
          </StyledAppNavigationSmall>
        </Drawer>
      </AppNavigationBar>
    );
  }

  return (
    <StyledAppNavigationLarge
      asChild
      gap={500}
      flexDirection="column"
      justifyContent="flex-start"
      data-test-id="app-navigation"
    >
      <aside>
        <AppNavigationLogo />
        <AppNavigationList />
        <ProfileMenu />
      </aside>
    </StyledAppNavigationLarge>
  );
};

const StyledAppNavigationLarge = styled(Flex)(
  ({ theme }) => css`
    position: sticky;
    padding: 0 ${theme.tokens.spaces[200]};
    padding-block-end: ${theme.tokens.spaces[200]};
    padding-block-start: ${theme.tokens.spaces[500]};
    top: 0;
    width: 240px;
    background: ${theme.backgroundPrimary};
    height: 100vh;
    box-sizing: border-box;
    border-inline-end: 1px solid ${theme.surfaceSecondary};
    overflow-y: auto;
  `
);

const StyledAppNavigationSmall = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.tokens.spaces[200]};
    background: ${theme.backgroundPrimary};
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
  `
);

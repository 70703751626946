export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AccountOption = {
  readonly __typename?: 'AccountOption';
  readonly accountId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly status: AccountOptionStatus;
};

export enum AccountOptionStatus {
  Available = 'AVAILABLE',
  Unavailablecardsnotsupported = 'UNAVAILABLECARDSNOTSUPPORTED',
  Unavailablelimitreached = 'UNAVAILABLELIMITREACHED',
}

export type AddNoteInput = {
  readonly note: Scalars['String']['input'];
  readonly transactionId: Scalars['String']['input'];
};

export type AddNoteResponse = {
  readonly __typename?: 'AddNoteResponse';
  readonly note: Scalars['String']['output'];
};

export type BillScanResponse = {
  readonly __typename?: 'BillScanResponse';
  readonly amount?: Maybe<Scalars['String']['output']>;
  readonly invoiceNumber?: Maybe<Scalars['String']['output']>;
  readonly paymentDueDate?: Maybe<Scalars['String']['output']>;
  readonly paymentScanID: Scalars['String']['output'];
  readonly sellerName?: Maybe<Scalars['String']['output']>;
  readonly slip?: Maybe<PaymentSlipScanInfo>;
  readonly transfer?: Maybe<PaymentTransferScanInfo>;
};

export type BillUploadResponse = {
  readonly __typename?: 'BillUploadResponse';
  readonly fileId: Scalars['String']['output'];
  readonly fileUploadUrl: Scalars['String']['output'];
};

export enum CardDesign {
  AstralisBlack = 'AstralisBlack',
  BusinessGold = 'BusinessGold',
  BusinessGrey = 'BusinessGrey',
  BusinessGrowSilver = 'BusinessGrowSilver',
  BusinessRoseGold = 'BusinessRoseGold',
  BusinessVirtualBlack = 'BusinessVirtualBlack',
  BusinessVirtualBlue = 'BusinessVirtualBlue',
  BusinessVirtualGreen = 'BusinessVirtualGreen',
  BusinessVirtualGrey = 'BusinessVirtualGrey',
  BusinessVirtualMint = 'BusinessVirtualMint',
  BusinessVirtualOrange = 'BusinessVirtualOrange',
  BusinessVirtualPearl = 'BusinessVirtualPearl',
  BusinessVirtualPine = 'BusinessVirtualPine',
  BusinessVirtualSky = 'BusinessVirtualSky',
  BusinessVirtualWhite = 'BusinessVirtualWhite',
  Dkem2020 = 'DKEM2020',
  FreemiumBlack = 'FreemiumBlack',
  No2022 = 'NO2022',
  PremiumGold = 'PremiumGold',
  PremiumGrey = 'PremiumGrey',
  PremiumRoseGold = 'PremiumRoseGold',
  ProBlack = 'ProBlack',
  ProRoseGold = 'ProRoseGold',
  ProSilver = 'ProSilver',
  Se2022 = 'SE2022',
  StandardBlack = 'StandardBlack',
  StandardCoral = 'StandardCoral',
  StandardGreen = 'StandardGreen',
  StandardSky = 'StandardSky',
  Virtual = 'Virtual',
  VirtualBlack = 'VirtualBlack',
  VirtualBlue = 'VirtualBlue',
  VirtualBusiness = 'VirtualBusiness',
  VirtualGreen = 'VirtualGreen',
  VirtualGrey = 'VirtualGrey',
  VirtualMint = 'VirtualMint',
  VirtualOrange = 'VirtualOrange',
  VirtualPearl = 'VirtualPearl',
  VirtualPine = 'VirtualPine',
  VirtualSky = 'VirtualSky',
  VirtualWhite = 'VirtualWhite',
}

export type CardDesignV2 = {
  readonly __typename?: 'CardDesignV2';
  readonly backgroundEndColor: Scalars['String']['output'];
  readonly backgroundStartColor: Scalars['String']['output'];
  readonly design: CardDesign;
  readonly frontOfCardUrl: Scalars['String']['output'];
};

export type CardExpiry = {
  readonly __typename?: 'CardExpiry';
  readonly month: Scalars['String']['output'];
  readonly year: Scalars['String']['output'];
};

export type CardImageUrl = {
  readonly __typename?: 'CardImageURL';
  readonly imageId: Scalars['String']['output'];
  readonly javascriptUrl: Scalars['String']['output'];
};

export type CardImageUrlInput = {
  readonly cardId: Scalars['ID']['input'];
  readonly requestId: Scalars['ID']['input'];
};

export type CardOption = {
  readonly __typename?: 'CardOption';
  readonly deliveryInformation: DeliveryInformation;
  readonly design: CardDesignV2;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly price: CardPrice;
  readonly priceListUrl: Scalars['String']['output'];
  readonly type: CardType;
  readonly visaTermsUrl: Scalars['String']['output'];
};

export type CardOrderLimits = {
  readonly __typename?: 'CardOrderLimits';
  readonly digital?: Maybe<OrderLimit>;
  readonly physical?: Maybe<OrderLimit>;
};

export type CardOrderOptions = {
  readonly __typename?: 'CardOrderOptions';
  readonly accountOptions: ReadonlyArray<AccountOption>;
  readonly cardOptions: ReadonlyArray<CardOption>;
  readonly deliveryAddress: Scalars['String']['output'];
  readonly deliveryName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type CardOrderOptionsInput = {
  readonly type: CardType;
};

export type CardPrice = {
  readonly __typename?: 'CardPrice';
  readonly amount: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly frequency: CardSubscriptionFrequency;
};

export type CardPriceInput = {
  readonly amount: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly frequency: CardSubscriptionFrequency;
};

export enum CardState {
  Active = 'Active',
  Blocked = 'Blocked',
  Deleted = 'Deleted',
  Frozen = 'Frozen',
  Ordered = 'Ordered',
}

export enum CardSubscriptionFrequency {
  Monthly = 'MONTHLY',
  Onetime = 'ONETIME',
  Yearly = 'YEARLY',
}

export enum CardType {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

export type Category = {
  readonly __typename?: 'Category';
  readonly amount: Scalars['Float']['output'];
  readonly color: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly deleted: Scalars['Boolean']['output'];
  readonly groupKey: Scalars['String']['output'];
  readonly iconURL: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly transactionCount: Scalars['Int']['output'];
};

export enum Country {
  Dk = 'DK',
  No = 'NO',
}

export type CreatePinInput = {
  readonly encryptedPin: Scalars['String']['input'];
  readonly encryptedTransportKey: Scalars['String']['input'];
};

export type CreatePinResponse = {
  readonly __typename?: 'CreatePinResponse';
  readonly pinId: Scalars['String']['output'];
};

export type CurrentUser = {
  readonly __typename?: 'CurrentUser';
  readonly AvatarURL: Scalars['String']['output'];
  readonly Description: Scalars['String']['output'];
  readonly DisplayName: Scalars['String']['output'];
  readonly Email: Scalars['String']['output'];
  readonly ExternalFields?: Maybe<ReadonlyArray<ExternalKeyValuePair>>;
  readonly ID: Scalars['String']['output'];
  readonly PhoneNumber: PhoneNumber;
  readonly ShortName: Scalars['String']['output'];
  readonly State: Scalars['String']['output'];
  readonly UserRelationType?: Maybe<UserRelationType>;
};

export type DeliveryInformation = {
  readonly __typename?: 'DeliveryInformation';
  readonly currency: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly priceAmount: Scalars['String']['output'];
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type DeliveryInformationInput = {
  readonly currency: Scalars['String']['input'];
  readonly id: Scalars['ID']['input'];
  readonly priceAmount: Scalars['String']['input'];
};

export enum ExportStatus {
  Discarded = 'DISCARDED',
  Initiated = 'INITIATED',
  Processed = 'PROCESSED',
}

export type ExternalKeyValuePair = {
  readonly __typename?: 'ExternalKeyValuePair';
  readonly Key: Scalars['String']['output'];
  readonly Value: Scalars['String']['output'];
};

export type FeatureEnabled = {
  readonly __typename?: 'FeatureEnabled';
  readonly id: Scalars['ID']['output'];
  readonly isEnabled: Scalars['Boolean']['output'];
  readonly title: Scalars['String']['output'];
};

export enum FileStatus {
  Discarded = 'DISCARDED',
  Initiated = 'INITIATED',
  Processed = 'PROCESSED',
}

export enum Interval {
  Month = 'MONTH',
  Week = 'WEEK',
}

export type LogInput = {
  readonly level: LogLevel;
  readonly message: Scalars['String']['input'];
  readonly userId?: InputMaybe<Scalars['String']['input']>;
};

export enum LogLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Warn = 'WARN',
}

export type Merchant = {
  readonly __typename?: 'Merchant';
  readonly amount: Scalars['Float']['output'];
  readonly currency: Scalars['String']['output'];
  readonly groupKey: Scalars['String']['output'];
  readonly groupTitle: Scalars['String']['output'];
  readonly iconURL: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly transactionCount: Scalars['Int']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly addNote: AddNoteResponse;
  readonly automatron_mmm_AdjustIncomeSorterAmount: AutomatronMmmAdjustIncomeSorterAmountResponse;
  readonly automatron_mmm_CreateIncomeSorter: AutomatronMmmCreateIncomeSorterResponse;
  readonly automatron_mmm_DeleteIncomeSorterRule: AutomatronMmmDeleteIncomeSorterResponse;
  readonly billys_billing_terraConnect: BillysBillingGetIntegrationResponse;
  readonly billys_billing_terraDelete: BillysBillingGetIntegrationResponse;
  readonly billys_billing_terraSetAccountMap: BillysBillingGetIntegrationResponse;
  readonly deleteStorageItem: Scalars['Boolean']['output'];
  readonly dinero_terraConnect: DineroGetIntegrationResponse;
  readonly dinero_terraDelete: DineroGetIntegrationResponse;
  readonly dinero_terraSetAccountMap: DineroGetIntegrationResponse;
  readonly dinero_terraSetOrganisation: DineroGetIntegrationResponse;
  readonly directdebit_createMandate: DirectdebitResponse;
  readonly directdebit_deleteInstruction: DirectdebitResponse;
  readonly directdebit_moveMandate: DirectdebitResponse;
  readonly directdebit_moveMandatesFromBank: DirectdebitResponse;
  readonly directdebit_revokeMandate: DirectdebitResponse;
  readonly directdebit_updateMandateNote: DirectdebitResponse;
  readonly economic_terraConnect: EconomicGetIntegrationResponse;
  readonly economic_terraDelete: EconomicGetIntegrationResponse;
  readonly economic_terraSetAccountMap: EconomicGetIntegrationResponse;
  readonly fortnox_connect: FortnoxGetIntegrationResponse;
  readonly fortnox_delete: FortnoxGetIntegrationResponse;
  readonly friend_deleteFriend: Scalars['Boolean']['output'];
  readonly friend_deleteInvalidFriends: Scalars['Boolean']['output'];
  readonly friend_terraAddIcon: FriendAddIconResponse;
  readonly friend_terraCreateFriend: FriendFriend;
  readonly friend_terraDeleteFriend: Scalars['Boolean']['output'];
  readonly friend_terraDeleteIcon: FriendFriend;
  readonly friend_terraUpdateFriend: FriendFriend;
  readonly froda_integration_terraFrodaConsent: FrodaIntegrationFrodaConsent;
  readonly international_payment_api_deleteDocumentationPayment: InternationalPaymentApiDeleteDocumentationPaymentResponse;
  readonly international_payment_api_executePayment: InternationalPaymentApiExecutePaymentResponse;
  readonly international_payment_api_initializePayment: InternationalPaymentApiInitializePaymentResponse;
  readonly international_payment_api_updateDocumentationPayment: InternationalPaymentApiUpdateDocumentationPaymentResponse;
  readonly international_payment_api_updatePayment: InternationalPaymentApiUpdatePaymentResponse;
  readonly international_payment_api_uploadDocumentationPayment: InternationalPaymentApiUploadDocumentationPaymentResponse;
  readonly log: Scalars['Boolean']['output'];
  readonly nemkonto_acceptAgreement: NemkontoAcceptResponse;
  readonly nemkonto_generateAgreement: NemkontoGenerationResponse;
  readonly noop: Scalars['Boolean']['output'];
  readonly premium_terraCancelPlanV2: PremiumCancelPlanV2Response;
  /** @deprecated Use terraOrderPlanV2 instead */
  readonly premium_terraOrderPlan: PremiumOrderPlanResponse;
  readonly premium_terraOrderPlanV2: PremiumOrderPlanV2Response;
  readonly setStorageItem: StorageItem;
  readonly terraAcceptAccountAgreement: TerraAcceptAccountAgreement;
  readonly terraAddAttachment: TerraAddAttachmentResponse;
  readonly terraAttachmentUrl: Scalars['String']['output'];
  readonly terraCreateBillScanUpload: BillUploadResponse;
  readonly terraCreateExport: TerraExportResponse;
  readonly terraCreatePin: CreatePinResponse;
  readonly terraCustomizeAccount: Scalars['Boolean']['output'];
  readonly terraDeleteAttachment: Scalars['Boolean']['output'];
  readonly terraDeleteCard: TerraCard;
  readonly terraFreezeCard: TerraCard;
  readonly terraInitiatePaymentDKPaymentSlip: TerraInitiatePaymentResponse;
  readonly terraInitiatePaymentDomesticCreditTransfer: TerraInitiatePaymentResponse;
  readonly terraOrderAccountAgreement: TerraOrderAccountAgreement;
  readonly terraOrderCard: TerraCard;
  readonly terraRenameCard: TerraCard;
  readonly terraScanBill: BillScanResponse;
  readonly terraUnfreezeCard: TerraCard;
  readonly terraUpdateUserSettings: TerraUserSettings;
  readonly visma_eaccounting_terraConnect: VismaEaccountingGetIntegrationResponse;
  readonly visma_eaccounting_terraDelete: VismaEaccountingGetIntegrationResponse;
  readonly visma_eaccounting_terraSetAccountMap: VismaEaccountingGetIntegrationResponse;
};

export type MutationAddNoteArgs = {
  input: AddNoteInput;
};

export type MutationAutomatronMmmAdjustIncomeSorterAmountArgs = {
  amount: Scalars['String']['input'];
  ruleId: Scalars['ID']['input'];
};

export type MutationAutomatronMmmCreateIncomeSorterArgs = {
  request: AutomatronMmmCreateIncomeSorterRequest;
};

export type MutationAutomatronMmmDeleteIncomeSorterRuleArgs = {
  ruleId: Scalars['ID']['input'];
};

export type MutationBillysBillingTerraConnectArgs = {
  input: BillysBillingConnectInput;
};

export type MutationBillysBillingTerraSetAccountMapArgs = {
  input: BillysBillingSetAccountMapInput;
};

export type MutationDeleteStorageItemArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDineroTerraConnectArgs = {
  input: DineroConnectInput;
};

export type MutationDineroTerraSetAccountMapArgs = {
  input: DineroSetAccountMapInput;
};

export type MutationDineroTerraSetOrganisationArgs = {
  input: DineroSetOrganizationInput;
};

export type MutationDirectdebitCreateMandateArgs = {
  request: DirectdebitCreateMandateRequest;
};

export type MutationDirectdebitDeleteInstructionArgs = {
  instructionId: Scalars['ID']['input'];
};

export type MutationDirectdebitMoveMandateArgs = {
  request: DirectdebitMoveMandateBetweenAccountRequest;
};

export type MutationDirectdebitMoveMandatesFromBankArgs = {
  request: DirectdebitMoveMandatesFromBankRequest;
};

export type MutationDirectdebitRevokeMandateArgs = {
  mandateId: Scalars['ID']['input'];
};

export type MutationDirectdebitUpdateMandateNoteArgs = {
  mandateId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type MutationEconomicTerraConnectArgs = {
  input?: InputMaybe<EconomicConnectInput>;
};

export type MutationEconomicTerraSetAccountMapArgs = {
  input: EconomicSetAccountMapInput;
};

export type MutationFortnoxConnectArgs = {
  input: FortnoxConnectInput;
};

export type MutationFriendDeleteFriendArgs = {
  friendID: Scalars['String']['input'];
  userID: Scalars['String']['input'];
};

export type MutationFriendDeleteInvalidFriendsArgs = {
  userID?: InputMaybe<Scalars['String']['input']>;
};

export type MutationFriendTerraAddIconArgs = {
  input: FriendAddIconInput;
};

export type MutationFriendTerraCreateFriendArgs = {
  input: FriendCreateFriendInput;
};

export type MutationFriendTerraDeleteFriendArgs = {
  input: FriendDeleteFriendInput;
};

export type MutationFriendTerraDeleteIconArgs = {
  input: FriendDeleteIconInput;
};

export type MutationFriendTerraUpdateFriendArgs = {
  input: FriendUpdateFriendInput;
};

export type MutationInternationalPaymentApiDeleteDocumentationPaymentArgs = {
  documentationID: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationInternationalPaymentApiExecutePaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationInternationalPaymentApiUpdateDocumentationPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationInternationalPaymentApiUpdatePaymentArgs = {
  id: Scalars['ID']['input'];
  payment: InternationalPaymentApiUpdatePaymentRequest;
};

export type MutationInternationalPaymentApiUploadDocumentationPaymentArgs = {
  file: InternationalPaymentApiUploadDocumentationRequest;
  id: Scalars['ID']['input'];
};

export type MutationLogArgs = {
  input?: InputMaybe<LogInput>;
};

export type MutationNemkontoAcceptAgreementArgs = {
  agreementId: Scalars['String']['input'];
};

export type MutationNemkontoGenerateAgreementArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationPremiumTerraOrderPlanArgs = {
  planId: PremiumPlanId;
  promocode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPremiumTerraOrderPlanV2Args = {
  frequency?: InputMaybe<PremiumFrequency>;
  planId: PremiumPlanId;
  promocode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetStorageItemArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type MutationTerraAcceptAccountAgreementArgs = {
  input?: InputMaybe<TerraAcceptAccountAgreementInput>;
};

export type MutationTerraAddAttachmentArgs = {
  transactionId: Scalars['String']['input'];
};

export type MutationTerraAttachmentUrlArgs = {
  input: TerraAttachmentInput;
};

export type MutationTerraCreateExportArgs = {
  input: TerraExportStatementInput;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTerraCreatePinArgs = {
  request: CreatePinInput;
};

export type MutationTerraCustomizeAccountArgs = {
  input: TerraCustomizeAccountInput;
};

export type MutationTerraDeleteAttachmentArgs = {
  input: TerraDeleteAttachmentInput;
};

export type MutationTerraDeleteCardArgs = {
  request: TerraDeleteCardInput;
};

export type MutationTerraFreezeCardArgs = {
  request: TerraFreezeCardInput;
};

export type MutationTerraInitiatePaymentDkPaymentSlipArgs = {
  input: TerraInititatePaymentDkPaymentSlipInput;
};

export type MutationTerraInitiatePaymentDomesticCreditTransferArgs = {
  input: TerraInititatePaymentDomesticCreditTransferInput;
};

export type MutationTerraOrderCardArgs = {
  request: OrderCardInput;
};

export type MutationTerraRenameCardArgs = {
  request: TerraRenameCardInput;
};

export type MutationTerraScanBillArgs = {
  input: ScanBillInput;
};

export type MutationTerraUnfreezeCardArgs = {
  request: TerraUnfreezeCardInput;
};

export type MutationTerraUpdateUserSettingsArgs = {
  input: TerraUpdateUserSettingsInput;
};

export type MutationVismaEaccountingTerraConnectArgs = {
  input: VismaEaccountingConnectInput;
};

export type MutationVismaEaccountingTerraSetAccountMapArgs = {
  input: VismaEaccountingSetAccountMapInput;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderCardInput = {
  readonly accountId: Scalars['String']['input'];
  readonly cardOptionId: Scalars['String']['input'];
  readonly cardPrice: CardPriceInput;
  readonly cardType: CardType;
  readonly deliveryInformation: DeliveryInformationInput;
  readonly pinId?: InputMaybe<Scalars['String']['input']>;
};

export type OrderLimit = {
  readonly __typename?: 'OrderLimit';
  readonly otherContent?: Maybe<OtherContent>;
  readonly planContent?: Maybe<PlanContent>;
  readonly reason: OrderLimitReason;
  readonly subscriptionContent?: Maybe<SubscriptionContent>;
};

export enum OrderLimitReason {
  Other = 'other',
  Plan = 'plan',
  Subscription = 'subscription',
}

export type OtherContent = {
  readonly __typename?: 'OtherContent';
  readonly daysRemaining?: Maybe<Scalars['Int']['output']>;
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PaymentSlipScanInfo = {
  readonly __typename?: 'PaymentSlipScanInfo';
  readonly creditorId: Scalars['String']['output'];
  readonly debtorId?: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
};

export type PaymentTransferScanInfo = {
  readonly __typename?: 'PaymentTransferScanInfo';
  readonly recipientBban: Scalars['String']['output'];
};

export type PhoneNumber = {
  readonly __typename?: 'PhoneNumber';
  readonly CountryCallingCode: Scalars['String']['output'];
  readonly Number: Scalars['String']['output'];
};

export type PinConfig = {
  readonly __typename?: 'PinConfig';
  readonly publicKey: Scalars['String']['output'];
};

export type PlanContent = {
  readonly __typename?: 'PlanContent';
  readonly upsellPlanId: Scalars['String']['output'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly GetTransactionByID?: Maybe<TerraTransaction>;
  readonly GetTransactionsByIDs: ReadonlyArray<TerraTransaction>;
  readonly accounting_integrations_terraGetAccountingSyncStatus: AccountingIntegrationsSyncStatusResponse;
  readonly accounting_integrations_terraGetIntegration: AccountingIntegrationsIntegration;
  readonly accounting_integrations_terraGetSettings: AccountingIntegrationsSettings;
  readonly automatron_mmm_GetIncomeSorterSetup: AutomatronMmmSetupResponse;
  readonly automatron_mmm_GetRulesForAccount: AutomatronMmmRuleResponse;
  readonly billys_billing_terraGetAccountMap: BillysBillingGetAccountMapResponse;
  readonly billys_billing_terraGetAccounts: BillysBillingGetAccountsResponse;
  readonly billys_billing_terraGetStatus: BillysBillingGetIntegrationResponse;
  readonly dinero_terraGetAccountMap: DineroGetAccountMapResponse;
  readonly dinero_terraGetAccounts: DineroGetAccountsResponse;
  readonly dinero_terraGetInstallation: DineroGetInstallationResponse;
  readonly dinero_terraGetIntegration: DineroGetIntegrationResponse;
  readonly dinero_terraGetOrganizationList: DineroGetOrganizationsResponse;
  readonly directdebit_checkSortCodeExists: DirectdebitResponse;
  readonly directdebit_getCreateMandateChallengeStatus: DirectdebitCreateMandateChallengeStatusResponse;
  readonly directdebit_getMandate: DirectdebitMandate;
  readonly directdebit_getMandates: ReadonlyArray<DirectdebitMandate>;
  readonly directdebit_getMoveBetweenOwnAccountChallengeStatus: DirectdebitMoveBetweenOwnAccountsChallengeStatusResponse;
  readonly directdebit_getMoveMandateStatus: DirectdebitMoveMandateStatus;
  readonly directdebit_getMoveMandatesChallengeStatus: DirectdebitMoveMandateChallengeStatusResponse;
  readonly economic_terraGetAccountMap: EconomicGetAccountMapResponse;
  readonly economic_terraGetAccounts: EconomicGetAccountsResponse;
  readonly economic_terraGetInstallation: EconomicGetInstallationResponse;
  readonly economic_terraGetIntegration: EconomicGetIntegrationResponse;
  readonly fortnox_getInstallation: FortnoxGetInstallationResponse;
  readonly fortnox_getIntegration: FortnoxGetIntegrationResponse;
  readonly friend_ping: Scalars['String']['output'];
  readonly friend_terraGetFriends: FriendGetFriendsResponse;
  readonly froda_integration_terraFrodaWebview: FrodaIntegrationFrodaWebview;
  readonly getStorage: ReadonlyArray<StorageItem>;
  readonly getStorageItem?: Maybe<StorageItem>;
  readonly insurance_terraInsurances: ReadonlyArray<InsuranceInsurance>;
  readonly international_payment_api_calculateIBAN: InternationalPaymentApiCalculateIbanResponse;
  readonly international_payment_api_getEstimatedPrice: InternationalPaymentApiEstimatedPriceResponse;
  readonly international_payment_api_getPayment: InternationalPaymentApiGetPaymentResponse;
  readonly international_payment_api_getPaymentCurrencies: InternationalPaymentApiPaymentCurrencyResponse;
  readonly international_payment_api_getPaymentPurposes: ReadonlyArray<InternationalPaymentApiPaymentPurpose>;
  readonly me: User;
  readonly nemkonto_status: NemkontoStatus;
  readonly premium_terraGetCurrentPlanV2: PremiumPlanV2;
  readonly premium_terraGetPlanConfirmInfo: PremiumConfirmInfo;
  /** @deprecated Use terraGetPlansV2 instead */
  readonly premium_terraGetPlans: ReadonlyArray<PremiumPlanDetails>;
  readonly premium_terraGetPlansCompareLink: PremiumGetPlansCompareLinkResponse;
  readonly premium_terraGetPlansV2: PremiumGetPlansV2Response;
  readonly stitching_state: ReadonlyArray<StitchingState>;
  readonly terraAccount: TerraAccount;
  readonly terraAccountCustomizations: ReadonlyArray<TerraAccountCustomization>;
  readonly terraAccounts: ReadonlyArray<TerraAccount>;
  readonly terraAttachments: TerraAttachmentsResponse;
  readonly terraExport: TerraExportResponse;
  readonly terraExportCustomerPDF: Scalars['String']['output'];
  readonly terraExportStatement: Scalars['String']['output'];
  readonly terraGetAvatar: UserAvatar;
  readonly terraGetBillUploadStatus: UploadStatusResponse;
  readonly terraGetCardImageURL: CardImageUrl;
  readonly terraGetCardLimits?: Maybe<CardOrderLimits>;
  readonly terraGetCardOrderOptions: CardOrderOptions;
  readonly terraGetCards: ReadonlyArray<TerraCard>;
  readonly terraGetPinConfig: PinConfig;
  readonly terraGetScanRateLimits: ScanRateLimitsResponse;
  readonly terraGetUserFeatures: ReadonlyArray<FeatureEnabled>;
  readonly terraGetUserRelation: UserRelationResponse;
  readonly terraOrderAccountOptions: TerraOrderAccountOptions;
  readonly terraPaymentInitiation: TerraInitiatePaymentResponse;
  readonly terraSpend: SpendResponse;
  readonly terraTransactions: TerraTransactionResponse;
  readonly terraUserSettings: TerraUserSettings;
  readonly transaction_search_searchTransactions: ReadonlyArray<
    Scalars['String']['output']
  >;
  readonly transfer_fee_terraPaymentFee: TransferFeeTransferFeeResponse;
  readonly transfer_fee_terraStandingOrderFee: TransferFeeTransferFeeResponse;
  readonly transfer_fee_terraTransferFee: TransferFeeTransferFeeResponse;
  readonly visma_eaccounting_terraGetAccountMap: VismaEaccountingGetAccountMapResponse;
  readonly visma_eaccounting_terraGetAccounts: VismaEaccountingGetAccountsResponse;
  readonly visma_eaccounting_terraGetInstallation: VismaEaccountingGetInstallationResponse;
  readonly visma_eaccounting_terraGetIntegration: VismaEaccountingGetIntegrationResponse;
};

export type QueryGetTransactionByIdArgs = {
  input: Scalars['String']['input'];
};

export type QueryGetTransactionsByIDsArgs = {
  input: ReadonlyArray<Scalars['String']['input']>;
};

export type QueryAccountingIntegrationsTerraGetAccountingSyncStatusArgs = {
  trxId: Scalars['String']['input'];
};

export type QueryAutomatronMmmGetRulesForAccountArgs = {
  accountId: Scalars['ID']['input'];
};

export type QueryDirectdebitCheckSortCodeExistsArgs = {
  sortCode: Scalars['String']['input'];
};

export type QueryDirectdebitGetCreateMandateChallengeStatusArgs = {
  requestId: Scalars['ID']['input'];
};

export type QueryDirectdebitGetMandateArgs = {
  mandateId: Scalars['ID']['input'];
};

export type QueryDirectdebitGetMoveBetweenOwnAccountChallengeStatusArgs = {
  requestId: Scalars['ID']['input'];
};

export type QueryDirectdebitGetMoveMandatesChallengeStatusArgs = {
  requestId: Scalars['ID']['input'];
};

export type QueryFriendTerraGetFriendsArgs = {
  filter?: InputMaybe<FriendFilter>;
};

export type QueryGetStorageItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInternationalPaymentApiCalculateIbanArgs = {
  calculateIBANRequest: InternationalPaymentApiCalculateIbanRequest;
};

export type QueryInternationalPaymentApiGetEstimatedPriceArgs = {
  input: InternationalPaymentApiEstimatedPriceInput;
};

export type QueryInternationalPaymentApiGetPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInternationalPaymentApiGetPaymentCurrenciesArgs = {
  paymentID: Scalars['ID']['input'];
};

export type QueryPremiumTerraGetPlanConfirmInfoArgs = {
  frequency: PremiumFrequency;
  planId: PremiumPlanId;
};

export type QueryTerraAccountArgs = {
  input: TerraAccountInput;
};

export type QueryTerraAttachmentsArgs = {
  transactionId: Scalars['String']['input'];
};

export type QueryTerraExportArgs = {
  fileId: Scalars['String']['input'];
};

export type QueryTerraExportCustomerPdfArgs = {
  accountId: Scalars['String']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type QueryTerraExportStatementArgs = {
  input: TerraExportStatementInput;
};

export type QueryTerraGetAvatarArgs = {
  userId: Scalars['String']['input'];
};

export type QueryTerraGetBillUploadStatusArgs = {
  fileId: Scalars['String']['input'];
};

export type QueryTerraGetCardImageUrlArgs = {
  request: CardImageUrlInput;
};

export type QueryTerraGetCardOrderOptionsArgs = {
  request: CardOrderOptionsInput;
};

export type QueryTerraPaymentInitiationArgs = {
  paymentId: Scalars['ID']['input'];
};

export type QueryTerraSpendArgs = {
  input: SpendInput;
};

export type QueryTerraTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: TerraTransactionsInput;
};

export type QueryTransactionSearchSearchTransactionsArgs = {
  query: TransactionSearchSearchTransactionsInput;
};

export type QueryTransferFeeTerraPaymentFeeArgs = {
  input?: InputMaybe<TransferFeePaymentFeeInput>;
};

export type QueryTransferFeeTerraStandingOrderFeeArgs = {
  input?: InputMaybe<TransferFeeStandingOrderFeeInput>;
};

export type QueryTransferFeeTerraTransferFeeArgs = {
  input?: InputMaybe<TransferFeeTransferFeeInput>;
};

export type ScanBillInput = {
  readonly fileId: Scalars['String']['input'];
};

export type ScanRateLimitsResponse = {
  readonly __typename?: 'ScanRateLimitsResponse';
  readonly resetDate: Scalars['String']['output'];
  readonly scansAfterReset: Scalars['Int']['output'];
  readonly scansLeft: Scalars['Int']['output'];
};

export enum SortBy {
  FinancialTime = 'FINANCIAL_TIME',
  FinancialTimeBeforeTime = 'FINANCIAL_TIME_BEFORE_TIME',
  Time = 'TIME',
}

export type SpendBucket = {
  readonly __typename?: 'SpendBucket';
  readonly categories: ReadonlyArray<Category>;
  readonly currency: Scalars['String']['output'];
  readonly expenses: Scalars['Float']['output'];
  readonly from: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly income: Scalars['Float']['output'];
  readonly incomeSources: ReadonlyArray<Merchant>;
  readonly incomingTransactionCount: Scalars['Int']['output'];
  readonly interval: Interval;
  readonly merchants: ReadonlyArray<Merchant>;
  readonly nonCategorised: Scalars['Int']['output'];
  readonly outGoingTransactionCount: Scalars['Int']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly to: Scalars['String']['output'];
  readonly totalTransactionCount: Scalars['Int']['output'];
};

export type SpendInput = {
  readonly accountIds: ReadonlyArray<Scalars['String']['input']>;
  readonly categoryId?: InputMaybe<Scalars['String']['input']>;
  readonly includeNumberOfPreviousPeriods: Scalars['Int']['input'];
  readonly merchantId?: InputMaybe<Scalars['String']['input']>;
  readonly offset: Scalars['Int']['input'];
};

export type SpendResponse = {
  readonly __typename?: 'SpendResponse';
  readonly buckets: ReadonlyArray<SpendBucket>;
};

export type StitchingState = {
  readonly __typename?: 'StitchingState';
  readonly error?: Maybe<Scalars['String']['output']>;
  readonly serviceName: Scalars['String']['output'];
  readonly successful: Scalars['Boolean']['output'];
  readonly updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StorageItem = {
  readonly __typename?: 'StorageItem';
  readonly id: Scalars['ID']['output'];
  readonly value: Scalars['String']['output'];
};

export type SubscriptionContent = {
  readonly __typename?: 'SubscriptionContent';
  readonly subscriptionPrice: Scalars['String']['output'];
  readonly upsellPlanId: Scalars['String']['output'];
};

export type TerraAcceptAccountAgreement = {
  readonly __typename?: 'TerraAcceptAccountAgreement';
  readonly accountId: Scalars['String']['output'];
  readonly state: TerraAgreementStatus;
};

export type TerraAcceptAccountAgreementInput = {
  readonly accountOrderId: Scalars['String']['input'];
  readonly customization: TerraCustomization;
  readonly expectedCurrency: Scalars['String']['input'];
  readonly expectedPrice: Scalars['String']['input'];
};

export type TerraAccount = {
  readonly __typename?: 'TerraAccount';
  readonly accountNumber: Scalars['String']['output'];
  readonly balanceAmount: Scalars['String']['output'];
  readonly balanceAvailableAmount: Scalars['String']['output'];
  readonly balanceGoalAmount: Scalars['String']['output'];
  readonly balanceUsableAmount: Scalars['String']['output'];
  readonly bicSwift: Scalars['String']['output'];
  readonly color: Scalars['String']['output'];
  readonly created: Scalars['String']['output'];
  readonly creditLimitAmount: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly displayName: Scalars['String']['output'];
  readonly iban: Scalars['String']['output'];
  readonly iconBackgroundUrl: Scalars['String']['output'];
  readonly iconUrl: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isPrimaryAccount: Scalars['Boolean']['output'];
  readonly issuingCountry: Scalars['String']['output'];
  readonly updated: Scalars['String']['output'];
};

export type TerraAccountCustomization = {
  readonly __typename?: 'TerraAccountCustomization';
  readonly available: Scalars['Boolean']['output'];
  readonly color: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly subType?: Maybe<TerraCustomizationSubType>;
  readonly type: TerraCustomizationType;
};

export type TerraAccountInput = {
  readonly accountId: Scalars['ID']['input'];
};

export type TerraAddAttachmentResponse = {
  readonly __typename?: 'TerraAddAttachmentResponse';
  readonly fileId: Scalars['String']['output'];
  readonly fileUploadUrl: Scalars['String']['output'];
};

export enum TerraAgreementStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export type TerraAttachment = {
  readonly __typename?: 'TerraAttachment';
  readonly canDelete: Scalars['Boolean']['output'];
  readonly created: Scalars['String']['output'];
  readonly fileId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly mimeType: Scalars['String']['output'];
};

export type TerraAttachmentInput = {
  readonly attachmentId: Scalars['ID']['input'];
  readonly transactionId: Scalars['ID']['input'];
};

export type TerraAttachmentsResponse = {
  readonly __typename?: 'TerraAttachmentsResponse';
  readonly attachments: ReadonlyArray<TerraAttachment>;
};

export type TerraCard = {
  readonly __typename?: 'TerraCard';
  readonly bankAccountId: Scalars['ID']['output'];
  readonly createdAt: Scalars['String']['output'];
  readonly design: CardDesign;
  readonly designV2: CardDesignV2;
  readonly expiry: CardExpiry;
  readonly id: Scalars['String']['output'];
  readonly maskedPan: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly state: CardState;
  readonly type: CardType;
};

export type TerraCardInfo = {
  readonly __typename?: 'TerraCardInfo';
  readonly cardId?: Maybe<Scalars['String']['output']>;
  readonly cardNumberMasked?: Maybe<Scalars['String']['output']>;
  readonly merchantInfo?: Maybe<TerraMerchantInfo>;
};

export enum TerraCloseReason {
  Fraud = 'Fraud',
  Lost = 'Lost',
  OnlyCardholderAccess = 'OnlyCardholderAccess',
  Other = 'Other',
  OthersAccess = 'OthersAccess',
  Stolen = 'Stolen',
  SuspectedFraud = 'SuspectedFraud',
  WearAndTear = 'WearAndTear',
}

export type TerraCustomization = {
  readonly iconBackgroundId: Scalars['String']['input'];
  readonly iconId: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export enum TerraCustomizationSubType {
  IconEmoji = 'ICON_EMOJI',
  IconWhite = 'ICON_WHITE',
}

export enum TerraCustomizationType {
  Background = 'BACKGROUND',
  Icon = 'ICON',
}

export type TerraCustomizeAccountInput = {
  readonly accountId: Scalars['String']['input'];
  readonly iconBackgroundId: Scalars['String']['input'];
  readonly iconId: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export enum TerraDkPaymentSlipType {
  Type_01 = 'TYPE_01',
  Type_04 = 'TYPE_04',
  Type_15 = 'TYPE_15',
  Type_71 = 'TYPE_71',
  Type_73 = 'TYPE_73',
  Type_75 = 'TYPE_75',
}

export type TerraDeleteAttachmentInput = {
  readonly fileId: Scalars['String']['input'];
  readonly transactionId: Scalars['String']['input'];
};

export type TerraDeleteCardInput = {
  readonly cardId: Scalars['ID']['input'];
  readonly reason: TerraCloseReason;
};

export type TerraExportResponse = {
  readonly __typename?: 'TerraExportResponse';
  readonly fileDownloadUrl?: Maybe<Scalars['String']['output']>;
  readonly fileId: Scalars['String']['output'];
  readonly status: ExportStatus;
};

export type TerraExportStatementInput = {
  readonly accountId: Scalars['String']['input'];
  readonly from: Scalars['String']['input'];
  readonly includeAttachments: Scalars['Boolean']['input'];
  readonly to: Scalars['String']['input'];
};

export type TerraFreezeCardInput = {
  readonly cardId: Scalars['ID']['input'];
};

export type TerraInitiatePaymentResponse = {
  readonly __typename?: 'TerraInitiatePaymentResponse';
  readonly approval?: Maybe<TerraInititatePaymentApproval>;
  readonly errorCode?: Maybe<Scalars['String']['output']>;
  readonly errorMessage?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly status: TerraInitiatePaymentStatus;
};

export enum TerraInitiatePaymentStatus {
  AwaitingApproval = 'AWAITING_APPROVAL',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export type TerraInititatePaymentApproval = {
  readonly __typename?: 'TerraInititatePaymentApproval';
  readonly approvedAt?: Maybe<Scalars['String']['output']>;
  readonly timeLeftSeconds: Scalars['Int']['output'];
};

export type TerraInititatePaymentDkPaymentSlipInput = {
  readonly accountId: Scalars['String']['input'];
  readonly amount: Scalars['String']['input'];
  readonly creditorId: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly date: Scalars['String']['input'];
  readonly debtorId?: InputMaybe<Scalars['String']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly paymentId?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly type: TerraDkPaymentSlipType;
};

export type TerraInititatePaymentDomesticCreditTransferInput = {
  readonly accountId: Scalars['String']['input'];
  readonly amount: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly date: Scalars['String']['input'];
  readonly immediate?: InputMaybe<Scalars['Boolean']['input']>;
  readonly messageToReceipient?: InputMaybe<Scalars['String']['input']>;
  readonly paymentId?: InputMaybe<Scalars['String']['input']>;
  readonly recipientBban: Scalars['String']['input'];
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export enum TerraLocale {
  Da = 'da',
  En = 'en',
  No = 'no',
  Sv = 'sv',
}

export type TerraMerchantInfo = {
  readonly __typename?: 'TerraMerchantInfo';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly countryAlpha2?: Maybe<Scalars['String']['output']>;
  readonly mcc?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly website?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

export type TerraMoney = {
  readonly __typename?: 'TerraMoney';
  readonly amount: Scalars['Float']['output'];
  readonly currency: Scalars['String']['output'];
};

export type TerraOrderAccountAgreement = {
  readonly __typename?: 'TerraOrderAccountAgreement';
  readonly accountOrderId: Scalars['String']['output'];
  readonly fileUrl: Scalars['String']['output'];
};

export type TerraOrderAccountOptions = {
  readonly __typename?: 'TerraOrderAccountOptions';
  readonly currency: Scalars['String']['output'];
  readonly hasOrdersInProgress?: Maybe<Scalars['Boolean']['output']>;
  readonly includedInPlan?: Maybe<Scalars['Boolean']['output']>;
  readonly limitReached?: Maybe<Scalars['Boolean']['output']>;
  readonly nextTierPlanId: Scalars['String']['output'];
  readonly nextTierPlanTotalFreeAccountsIncluded: Scalars['Int']['output'];
  readonly price: Scalars['String']['output'];
  readonly upgradeRequiredForSharedAccount?: Maybe<
    Scalars['Boolean']['output']
  >;
  readonly upgradeWouldResultInFreeAccount?: Maybe<
    Scalars['Boolean']['output']
  >;
};

export type TerraPaymentInfo = {
  readonly __typename?: 'TerraPaymentInfo';
  readonly creditorId?: Maybe<Scalars['String']['output']>;
  readonly debtorId?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export enum TerraRegion {
  Dk = 'DK',
  No = 'NO',
  Se = 'SE',
}

export type TerraRenameCardInput = {
  readonly cardId: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
};

export enum TerraTheme {
  Dark = 'DARK',
  Light = 'LIGHT',
  System = 'SYSTEM',
}

export type TerraTransaction = {
  readonly __typename?: 'TerraTransaction';
  readonly accountId: Scalars['String']['output'];
  readonly attachments?: Maybe<ReadonlyArray<TerraAttachment>>;
  readonly badgeUrl?: Maybe<Scalars['String']['output']>;
  readonly balance?: Maybe<TerraMoney>;
  readonly billingAmount: TerraMoney;
  readonly billingTime?: Maybe<Scalars['String']['output']>;
  readonly cardInfo?: Maybe<TerraCardInfo>;
  readonly from?: Maybe<Scalars['String']['output']>;
  readonly iconBackgroundUrl?: Maybe<Scalars['String']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly note?: Maybe<Scalars['String']['output']>;
  readonly status: TerraTransactionStatus;
  readonly title: Scalars['String']['output'];
  readonly to?: Maybe<Scalars['String']['output']>;
  readonly transactionAmount?: Maybe<TerraMoney>;
  readonly transactionTime: Scalars['String']['output'];
  readonly type: TerraTransactionType;
};

export type TerraTransactionResponse = {
  readonly __typename?: 'TerraTransactionResponse';
  readonly cursor: Scalars['String']['output'];
  readonly transactionCount: Scalars['Int']['output'];
  readonly transactions: ReadonlyArray<TerraTransaction>;
};

export enum TerraTransactionStatus {
  Authorization = 'authorization',
  Declined = 'declined',
  FailedAuthorization = 'failed_authorization',
  Financial = 'financial',
  Future = 'future',
  Initiated = 'initiated',
  Interim = 'interim',
  PotentialFuture = 'potentialFuture',
  Unknown = 'unknown',
}

export enum TerraTransactionType {
  Account = 'account',
  AutomaticTransfer = 'automaticTransfer',
  Card = 'card',
  DirectDebit = 'directDebit',
  Goal = 'goal',
  InternationalPayment = 'internationalPayment',
  Payment = 'payment',
  Unknown = 'unknown',
}

export type TerraTransactionsInput = {
  readonly accountId?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['String']['input']>;
  readonly hasAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  readonly order?: InputMaybe<Order>;
  readonly sortBy?: InputMaybe<SortBy>;
  readonly startDate?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<TerraTransactionStatus>;
};

export type TerraTransferInfo = {
  readonly __typename?: 'TerraTransferInfo';
  readonly messageToRecipient?: Maybe<Scalars['String']['output']>;
  readonly recipientBBAN?: Maybe<Scalars['String']['output']>;
};

export type TerraUnfreezeCardInput = {
  readonly cardId: Scalars['ID']['input'];
};

export type TerraUpdateUserSettingsInput = {
  readonly locale?: InputMaybe<TerraLocale>;
  readonly region?: InputMaybe<TerraRegion>;
  readonly theme?: InputMaybe<TerraTheme>;
};

export type TerraUserSettings = {
  readonly __typename?: 'TerraUserSettings';
  readonly id: Scalars['ID']['output'];
  readonly locale?: Maybe<TerraLocale>;
  readonly region?: Maybe<TerraRegion>;
  readonly theme?: Maybe<TerraTheme>;
};

export type UploadStatusResponse = {
  readonly __typename?: 'UploadStatusResponse';
  readonly fileId: Scalars['String']['output'];
  readonly fileUrl: Scalars['String']['output'];
  readonly status: FileStatus;
};

export type User = {
  readonly __typename?: 'User';
  readonly id: Scalars['ID']['output'];
};

export type UserAvatar = {
  readonly __typename?: 'UserAvatar';
  readonly avatarUrl: Scalars['String']['output'];
};

export type UserRelation = {
  readonly __typename?: 'UserRelation';
  readonly AvatarURL: Scalars['String']['output'];
  readonly Description: Scalars['String']['output'];
  readonly DisplayName: Scalars['String']['output'];
  readonly Email: Scalars['String']['output'];
  readonly ID: Scalars['String']['output'];
  readonly PhoneNumber: PhoneNumber;
  readonly ShortName: Scalars['String']['output'];
  readonly State: Scalars['String']['output'];
  readonly UserRelationType?: Maybe<UserRelationType>;
};

export type UserRelationContent = {
  readonly __typename?: 'UserRelationContent';
  readonly CurrentUser: CurrentUser;
  readonly UserRelations?: Maybe<ReadonlyArray<UserRelation>>;
};

export type UserRelationDescription = {
  readonly __typename?: 'UserRelationDescription';
  readonly Arguments?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly Description: Scalars['String']['output'];
};

export type UserRelationResponse = {
  readonly __typename?: 'UserRelationResponse';
  readonly Content?: Maybe<UserRelationContent>;
  readonly Error?: Maybe<Scalars['String']['output']>;
  readonly Success: Scalars['Boolean']['output'];
};

export type UserRelationType = {
  readonly __typename?: 'UserRelationType';
  readonly CountryCode: Scalars['String']['output'];
  readonly Value: Scalars['String']['output'];
};

export type AccountingIntegrationsIntegration = {
  readonly __typename?: 'accounting_integrations_Integration';
  readonly account: Scalars['String']['output'];
  readonly integrationType: AccountingIntegrationsIntegrationType;
  readonly isEnabled: Scalars['Boolean']['output'];
  readonly updatedAt: Scalars['String']['output'];
  readonly userId: Scalars['String']['output'];
};

export enum AccountingIntegrationsIntegrationType {
  Billy = 'billy',
  Dinero = 'dinero',
  Eaccounting = 'eaccounting',
  Economic = 'economic',
  Fortnox = 'fortnox',
  None = 'none',
}

export type AccountingIntegrationsSettings = {
  readonly __typename?: 'accounting_integrations_Settings';
  readonly upsellPlanId?: Maybe<Scalars['ID']['output']>;
};

export enum AccountingIntegrationsSyncStatus {
  Empty = 'Empty',
  NotSynced = 'NotSynced',
  SyncFailed = 'SyncFailed',
  Synced = 'Synced',
}

export type AccountingIntegrationsSyncStatusResponse = {
  readonly __typename?: 'accounting_integrations_SyncStatusResponse';
  readonly integrationType: AccountingIntegrationsIntegrationType;
  readonly syncStatus: AccountingIntegrationsSyncStatus;
};

export type AutomatronMmmAdjustAmountError = {
  readonly __typename?: 'automatron_mmm_AdjustAmountError';
  readonly message: Scalars['String']['output'];
};

export type AutomatronMmmAdjustIncomeSorterAmountResponse = {
  readonly __typename?: 'automatron_mmm_AdjustIncomeSorterAmountResponse';
  readonly error?: Maybe<AutomatronMmmAdjustAmountError>;
  readonly rule?: Maybe<AutomatronMmmIncomeSorterRule>;
};

export type AutomatronMmmCreateIncomeSorterRequest = {
  readonly amount: Scalars['String']['input'];
  readonly fromAccountId: Scalars['ID']['input'];
  readonly requestId: Scalars['ID']['input'];
  readonly toAccountId: Scalars['ID']['input'];
};

export type AutomatronMmmCreateIncomeSorterResponse = {
  readonly __typename?: 'automatron_mmm_CreateIncomeSorterResponse';
  readonly error?: Maybe<AutomatronMmmCreateRuleError>;
  readonly rule?: Maybe<AutomatronMmmIncomeSorterRule>;
};

export type AutomatronMmmCreateRuleError = {
  readonly __typename?: 'automatron_mmm_CreateRuleError';
  readonly message: Scalars['String']['output'];
};

export type AutomatronMmmDeleteIncomeSorterResponse = {
  readonly __typename?: 'automatron_mmm_DeleteIncomeSorterResponse';
  readonly error?: Maybe<AutomatronMmmDeleteRuleError>;
  readonly success: Scalars['Boolean']['output'];
};

export type AutomatronMmmDeleteRuleError = {
  readonly __typename?: 'automatron_mmm_DeleteRuleError';
  readonly message: Scalars['String']['output'];
};

export type AutomatronMmmIncomeSorterRule = {
  readonly __typename?: 'automatron_mmm_IncomeSorterRule';
  readonly amount: Scalars['String']['output'];
  readonly fromAccountId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly toAccountId: Scalars['ID']['output'];
};

export type AutomatronMmmRuleResponse = {
  readonly __typename?: 'automatron_mmm_RuleResponse';
  readonly incomeSorterRules: ReadonlyArray<AutomatronMmmIncomeSorterRule>;
};

export type AutomatronMmmSetupResponse = {
  readonly __typename?: 'automatron_mmm_SetupResponse';
  readonly defaultAmount: Scalars['String']['output'];
};

export type BillysBillingAccount = {
  readonly __typename?: 'billys_billing_Account';
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type BillysBillingAccountMapping = {
  readonly __typename?: 'billys_billing_AccountMapping';
  readonly from: Scalars['ID']['output'];
  readonly to: BillysBillingAccount;
};

export type BillysBillingAccountMappingInput = {
  readonly from: Scalars['ID']['input'];
  readonly to: Scalars['ID']['input'];
};

export type BillysBillingCategory = {
  readonly __typename?: 'billys_billing_Category';
  readonly accounts: ReadonlyArray<BillysBillingAccount>;
  readonly id: Scalars['String']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type BillysBillingConnectInput = {
  readonly code: Scalars['String']['input'];
};

export type BillysBillingDaybook = {
  readonly __typename?: 'billys_billing_Daybook';
  readonly id: Scalars['String']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type BillysBillingGetAccountMapResponse = {
  readonly __typename?: 'billys_billing_GetAccountMapResponse';
  readonly accountMap: ReadonlyArray<BillysBillingAccountMapping>;
  readonly daybookId: Scalars['ID']['output'];
};

export type BillysBillingGetAccountsResponse = {
  readonly __typename?: 'billys_billing_GetAccountsResponse';
  readonly categories: ReadonlyArray<BillysBillingCategory>;
  readonly daybooks: ReadonlyArray<BillysBillingDaybook>;
};

export type BillysBillingGetIntegrationResponse = {
  readonly __typename?: 'billys_billing_GetIntegrationResponse';
  readonly integration?: Maybe<BillysBillingIntegration>;
};

export type BillysBillingIntegration = {
  readonly __typename?: 'billys_billing_Integration';
  readonly status: BillysBillingStatus;
};

export type BillysBillingSetAccountMapInput = {
  readonly accountMap: ReadonlyArray<BillysBillingAccountMappingInput>;
  readonly daybookId: Scalars['ID']['input'];
};

export enum BillysBillingStatus {
  Active = 'Active',
  Connected = 'Connected',
  None = 'None',
}

export type DineroAccount = {
  readonly __typename?: 'dinero_Account';
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type DineroAccountMapping = {
  readonly __typename?: 'dinero_AccountMapping';
  readonly from: Scalars['ID']['output'];
  readonly to: Scalars['ID']['output'];
};

export type DineroAccountMappingInput = {
  readonly from: Scalars['ID']['input'];
  readonly to: Scalars['ID']['input'];
};

export type DineroCategory = {
  readonly __typename?: 'dinero_Category';
  readonly accounts: ReadonlyArray<DineroAccount>;
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type DineroConnectInput = {
  readonly authorizationCode: Scalars['String']['input'];
};

export type DineroGetAccountMapResponse = {
  readonly __typename?: 'dinero_GetAccountMapResponse';
  readonly accountMap: ReadonlyArray<DineroAccountMapping>;
};

export type DineroGetAccountsResponse = {
  readonly __typename?: 'dinero_GetAccountsResponse';
  readonly categories: ReadonlyArray<DineroCategory>;
};

export type DineroGetInstallationResponse = {
  readonly __typename?: 'dinero_GetInstallationResponse';
  readonly url: Scalars['String']['output'];
};

export type DineroGetIntegrationResponse = {
  readonly __typename?: 'dinero_GetIntegrationResponse';
  readonly integration?: Maybe<DineroIntegration>;
};

export type DineroGetOrganizationsResponse = {
  readonly __typename?: 'dinero_GetOrganizationsResponse';
  readonly organizations: ReadonlyArray<DineroOrganization>;
};

export type DineroIntegration = {
  readonly __typename?: 'dinero_Integration';
  readonly id: Scalars['ID']['output'];
  readonly status: DineroStatus;
};

export type DineroOrganization = {
  readonly __typename?: 'dinero_Organization';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type DineroSetAccountMapInput = {
  readonly accountMap: ReadonlyArray<DineroAccountMappingInput>;
};

export type DineroSetOrganizationInput = {
  readonly organizationId: Scalars['String']['input'];
};

export enum DineroStatus {
  Active = 'Active',
  Connected = 'Connected',
  None = 'None',
  SelectOrg = 'SelectOrg',
}

export enum DirectdebitChallengeStatus {
  Approved = 'APPROVED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  TimedOut = 'TIMED_OUT',
  Unknown = 'UNKNOWN',
}

export type DirectdebitCreateMandateChallengeStatusResponse = {
  readonly __typename?: 'directdebit_CreateMandateChallengeStatusResponse';
  readonly status: DirectdebitChallengeStatus;
};

export type DirectdebitCreateMandateRequest = {
  readonly accountID: Scalars['ID']['input'];
  readonly customerNumber: Scalars['String']['input'];
  readonly debtorGroupNumber: Scalars['String']['input'];
  readonly pbsNumber: Scalars['String']['input'];
  readonly requestID: Scalars['ID']['input'];
};

export enum DirectdebitHistoryStatus {
  Active = 'ACTIVE',
  Done = 'DONE',
  Failed = 'FAILED',
  Inactive = 'INACTIVE',
}

export type DirectdebitInstruction = {
  readonly __typename?: 'directdebit_Instruction';
  readonly accountID?: Maybe<Scalars['String']['output']>;
  readonly amount?: Maybe<Scalars['String']['output']>;
  readonly badgeUrl?: Maybe<Scalars['String']['output']>;
  readonly canReject: Scalars['Boolean']['output'];
  readonly collectionDate?: Maybe<Scalars['String']['output']>;
  readonly creditorFullName?: Maybe<Scalars['String']['output']>;
  readonly currency?: Maybe<Scalars['String']['output']>;
  readonly hasAttachment?: Maybe<Scalars['Boolean']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isFuture?: Maybe<Scalars['Boolean']['output']>;
  readonly longDescription?: Maybe<Scalars['String']['output']>;
  readonly mandateCustomerNumber?: Maybe<Scalars['String']['output']>;
  readonly mandateName?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<DirectdebitInstructionStatus>;
  readonly statusText?: Maybe<Scalars['String']['output']>;
};

export enum DirectdebitInstructionStatus {
  ReadyForExecution = 'READY_FOR_EXECUTION',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Rejecting = 'REJECTING',
  Reversed = 'REVERSED',
  Reversing = 'REVERSING',
  SettledRejectionPossible = 'SETTLED_REJECTION_POSSIBLE',
  SettlementCompleted = 'SETTLEMENT_COMPLETED',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
}

export type DirectdebitMandate = {
  readonly __typename?: 'directdebit_Mandate';
  readonly accountID?: Maybe<Scalars['String']['output']>;
  readonly canMoveAccount: Scalars['Boolean']['output'];
  readonly canMoveUpcomingInstructions: Scalars['Boolean']['output'];
  readonly canRevoke: Scalars['Boolean']['output'];
  readonly creditorName?: Maybe<Scalars['String']['output']>;
  readonly customerNumber?: Maybe<Scalars['String']['output']>;
  readonly dateEffective?: Maybe<Scalars['String']['output']>;
  readonly debtorGroupNumber?: Maybe<Scalars['String']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly instructions: ReadonlyArray<DirectdebitInstruction>;
  readonly mandateNumber?: Maybe<Scalars['String']['output']>;
  readonly note?: Maybe<Scalars['String']['output']>;
  readonly pbsNumber?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<DirectdebitMandateStatus>;
  readonly statusText?: Maybe<Scalars['String']['output']>;
};

export enum DirectdebitMandateStatus {
  Active = 'ACTIVE',
  Modifying = 'MODIFYING',
  Received = 'RECEIVED',
  Revoked = 'REVOKED',
  Revoking = 'REVOKING',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN',
}

export type DirectdebitMoveBetweenOwnAccountsChallengeStatusResponse = {
  readonly __typename?: 'directdebit_MoveBetweenOwnAccountsChallengeStatusResponse';
  readonly status: DirectdebitChallengeStatus;
};

export type DirectdebitMoveMandateBetweenAccountRequest = {
  readonly mandateId: Scalars['ID']['input'];
  readonly requestId: Scalars['ID']['input'];
  readonly toAccountID: Scalars['ID']['input'];
};

export type DirectdebitMoveMandateChallengeStatusResponse = {
  readonly __typename?: 'directdebit_MoveMandateChallengeStatusResponse';
  readonly status: DirectdebitChallengeStatus;
};

export type DirectdebitMoveMandateHistory = {
  readonly __typename?: 'directdebit_MoveMandateHistory';
  readonly sort?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<DirectdebitHistoryStatus>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type DirectdebitMoveMandateStatus = {
  readonly __typename?: 'directdebit_MoveMandateStatus';
  readonly history: ReadonlyArray<DirectdebitMoveMandateHistory>;
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type DirectdebitMoveMandatesFromBankRequest = {
  readonly accountID: Scalars['ID']['input'];
  readonly requestID: Scalars['ID']['input'];
  readonly sortCode: Scalars['String']['input'];
};

export type DirectdebitResponse = {
  readonly __typename?: 'directdebit_Response';
  readonly errorMessage?: Maybe<Scalars['String']['output']>;
  readonly successful: Scalars['Boolean']['output'];
};

export type EconomicAccount = {
  readonly __typename?: 'economic_Account';
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type EconomicAccountMapping = {
  readonly __typename?: 'economic_AccountMapping';
  readonly from: Scalars['ID']['output'];
  readonly to: Scalars['ID']['output'];
};

export type EconomicAccountMappingInput = {
  readonly from: Scalars['ID']['input'];
  readonly to: Scalars['ID']['input'];
};

export type EconomicCategory = {
  readonly __typename?: 'economic_Category';
  readonly accounts: ReadonlyArray<EconomicAccount>;
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type EconomicConnectInput = {
  readonly token: Scalars['String']['input'];
};

export type EconomicGetAccountMapResponse = {
  readonly __typename?: 'economic_GetAccountMapResponse';
  readonly accountMap: ReadonlyArray<EconomicAccountMapping>;
};

export type EconomicGetAccountsResponse = {
  readonly __typename?: 'economic_GetAccountsResponse';
  readonly categories: ReadonlyArray<EconomicCategory>;
  readonly journals: ReadonlyArray<EconomicJournal>;
};

export type EconomicGetInstallationResponse = {
  readonly __typename?: 'economic_GetInstallationResponse';
  readonly url: Scalars['String']['output'];
};

export type EconomicGetIntegrationResponse = {
  readonly __typename?: 'economic_GetIntegrationResponse';
  readonly integration?: Maybe<EconomicIntegration>;
};

export type EconomicIntegration = {
  readonly __typename?: 'economic_Integration';
  readonly id: Scalars['ID']['output'];
  readonly status: EconomicStatus;
};

export type EconomicJournal = {
  readonly __typename?: 'economic_Journal';
  readonly id: Scalars['String']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type EconomicSetAccountMapInput = {
  readonly accountMap: ReadonlyArray<EconomicAccountMappingInput>;
  readonly journalId: Scalars['ID']['input'];
};

export enum EconomicStatus {
  Active = 'Active',
  Connected = 'Connected',
  None = 'None',
}

export type FortnoxConnectInput = {
  readonly code: Scalars['String']['input'];
};

export type FortnoxGetInstallationResponse = {
  readonly __typename?: 'fortnox_GetInstallationResponse';
  readonly url: Scalars['String']['output'];
};

export type FortnoxGetIntegrationResponse = {
  readonly __typename?: 'fortnox_GetIntegrationResponse';
  readonly integration?: Maybe<FortnoxIntegration>;
};

export type FortnoxIntegration = {
  readonly __typename?: 'fortnox_Integration';
  readonly status: FortnoxStatus;
};

export enum FortnoxStatus {
  Active = 'Active',
  Connected = 'Connected',
  None = 'None',
}

export type FriendAddIconInput = {
  readonly friendId: Scalars['ID']['input'];
};

export type FriendAddIconResponse = {
  readonly __typename?: 'friend_AddIconResponse';
  readonly fileId: Scalars['ID']['output'];
  readonly fileUploadUrl: Scalars['String']['output'];
};

export type FriendCreateFriendInput = {
  readonly accountNumber: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly transferType: FriendTransferType;
};

export type FriendDeleteFriendInput = {
  readonly friendId: Scalars['ID']['input'];
};

export type FriendDeleteIconInput = {
  readonly friendId: Scalars['ID']['input'];
};

export type FriendFilter = {
  readonly transferType?: InputMaybe<FriendTransferType>;
};

export type FriendFriend = {
  readonly __typename?: 'friend_Friend';
  readonly accountBankName: Scalars['String']['output'];
  readonly accountNumber: Scalars['String']['output'];
  readonly accountNumberType: Scalars['String']['output'];
  readonly color: Scalars['String']['output'];
  readonly displayAccountNumber: Scalars['String']['output'];
  readonly iconUrl: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly saved: Scalars['Boolean']['output'];
  readonly transferType: FriendTransferType;
  readonly type: FriendType;
};

export type FriendGetFriendsResponse = {
  readonly __typename?: 'friend_GetFriendsResponse';
  readonly friends: ReadonlyArray<FriendFriend>;
};

export enum FriendTransferType {
  International = 'international',
  National = 'national',
}

export enum FriendType {
  External = 'external',
  User = 'user',
}

export type FriendUpdateFriendInput = {
  readonly friendId: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
};

export type FrodaIntegrationFrodaConsent = {
  readonly __typename?: 'froda_integration_FrodaConsent';
  readonly hasConsent: Scalars['Boolean']['output'];
};

export type FrodaIntegrationFrodaWebview = {
  readonly __typename?: 'froda_integration_FrodaWebview';
  readonly hasConsent: Scalars['Boolean']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type InsuranceInsurance = {
  readonly __typename?: 'insurance_Insurance';
  readonly claimEmail?: Maybe<Scalars['String']['output']>;
  readonly claimPhoneNumber?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly emergencyPhoneNumber?: Maybe<Scalars['String']['output']>;
  readonly expiresAt?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly policyFileUrl?: Maybe<Scalars['String']['output']>;
  readonly policyNumber: Scalars['String']['output'];
  readonly renewsAt: Scalars['String']['output'];
  readonly status: InsuranceInsuranceStatus;
  readonly subType: InsuranceInsuranceSubType;
  readonly type: InsuranceInsuranceType;
  readonly userId: Scalars['String']['output'];
};

export enum InsuranceInsuranceStatus {
  Active = 'ACTIVE',
  ActiveCancelled = 'ACTIVE_CANCELLED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
}

export enum InsuranceInsuranceSubType {
  Cyber = 'CYBER',
  TravelEurope = 'TRAVEL_EUROPE',
  TravelWorld = 'TRAVEL_WORLD',
}

export enum InsuranceInsuranceType {
  Grow = 'GROW',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  Travel = 'TRAVEL',
}

export enum InternationalPaymentApiAccountNumberType {
  BicSwift = 'BIC_SWIFT',
  Iban = 'IBAN',
}

export type InternationalPaymentApiBank = {
  readonly __typename?: 'international_payment_api_Bank';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type InternationalPaymentApiCalculateIbanRequest = {
  readonly accountNumber: Scalars['String']['input'];
  readonly bankCode: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
};

export type InternationalPaymentApiCalculateIbanResponse = {
  readonly __typename?: 'international_payment_api_CalculateIBANResponse';
  readonly bank: InternationalPaymentApiBank;
  readonly bicSwift: Scalars['String']['output'];
  readonly iban: Scalars['String']['output'];
};

export type InternationalPaymentApiDeleteDocumentationPaymentResponse = {
  readonly __typename?: 'international_payment_api_DeleteDocumentationPaymentResponse';
  readonly errorMessage?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type InternationalPaymentApiDocumentation = {
  readonly __typename?: 'international_payment_api_Documentation';
  readonly fileName?: Maybe<Scalars['String']['output']>;
  readonly fileUrl?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['ID']['output']>;
  readonly mineType?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<InternationalPaymentApiDocumentationStatus>;
};

export enum InternationalPaymentApiDocumentationStatus {
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export type InternationalPaymentApiEstimatedPriceInput = {
  readonly amount: Scalars['String']['input'];
  readonly fromCurrency: Scalars['String']['input'];
  readonly toCurrency: Scalars['String']['input'];
};

export type InternationalPaymentApiEstimatedPriceResponse = {
  readonly __typename?: 'international_payment_api_EstimatedPriceResponse';
  readonly currencyRate: Scalars['Float']['output'];
  readonly currencyRateFeeAmount: Scalars['String']['output'];
  readonly currencyRateFeeCurrency: Scalars['String']['output'];
  readonly currencyRateFeePercent: Scalars['Float']['output'];
  readonly financialAmount: Scalars['String']['output'];
  readonly financialAmountCurrency: Scalars['String']['output'];
  readonly fixedFeeAmount: Scalars['String']['output'];
  readonly fixedFeeCurrency: Scalars['String']['output'];
  readonly totalChargeAmount: Scalars['String']['output'];
  readonly totalChargeCurrency: Scalars['String']['output'];
};

export type InternationalPaymentApiExecutePaymentResponse = {
  readonly __typename?: 'international_payment_api_ExecutePaymentResponse';
  readonly payment: InternationalPaymentApiPayment;
};

export enum InternationalPaymentApiExecutionStatus {
  AwaitingChallenge = 'AWAITING_CHALLENGE',
  Executed = 'EXECUTED',
  Failed = 'FAILED',
  None = 'NONE',
}

export type InternationalPaymentApiGetPaymentResponse = {
  readonly __typename?: 'international_payment_api_GetPaymentResponse';
  readonly payment: InternationalPaymentApiPayment;
};

export type InternationalPaymentApiInitializePaymentResponse = {
  readonly __typename?: 'international_payment_api_InitializePaymentResponse';
  readonly payment: InternationalPaymentApiPayment;
};

export type InternationalPaymentApiPayment = {
  readonly __typename?: 'international_payment_api_Payment';
  readonly accountID?: Maybe<Scalars['ID']['output']>;
  readonly accountNumber?: Maybe<Scalars['String']['output']>;
  readonly accountNumberRequired?: Maybe<Scalars['Boolean']['output']>;
  readonly amount?: Maybe<Scalars['String']['output']>;
  readonly bankAddress?: Maybe<Scalars['String']['output']>;
  readonly bankCountry?: Maybe<Scalars['String']['output']>;
  readonly bankName?: Maybe<Scalars['String']['output']>;
  readonly bicSwift?: Maybe<Scalars['String']['output']>;
  readonly currency?: Maybe<Scalars['String']['output']>;
  readonly documentation?: Maybe<
    ReadonlyArray<Maybe<InternationalPaymentApiDocumentation>>
  >;
  readonly documentationRequired?: Maybe<Scalars['Boolean']['output']>;
  readonly estimatedPrice?: Maybe<InternationalPaymentApiEstimatedPriceResponse>;
  readonly eta?: Maybe<Scalars['String']['output']>;
  readonly executionStatus?: Maybe<InternationalPaymentApiExecutionStatus>;
  readonly executionStatusMessage?: Maybe<Scalars['String']['output']>;
  readonly failedReasonText?: Maybe<Scalars['String']['output']>;
  readonly iban?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly purpose?: Maybe<InternationalPaymentApiPaymentPurposeEnum>;
  readonly purposeOther?: Maybe<Scalars['String']['output']>;
  readonly purposeRequired?: Maybe<Scalars['Boolean']['output']>;
  readonly recipientAccountNumberType?: Maybe<InternationalPaymentApiAccountNumberType>;
  readonly recipientAddress?: Maybe<
    ReadonlyArray<Maybe<Scalars['String']['output']>>
  >;
  readonly recipientAddressAllowed?: Maybe<Scalars['Boolean']['output']>;
  readonly recipientName?: Maybe<Scalars['String']['output']>;
  readonly recipientType?: Maybe<InternationalPaymentApiRecipientType>;
  readonly requiredPlanUpgrade?: Maybe<Scalars['String']['output']>;
};

export type InternationalPaymentApiPaymentCurrency = {
  readonly __typename?: 'international_payment_api_PaymentCurrency';
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly default?: Maybe<Scalars['Boolean']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly popular?: Maybe<Scalars['Boolean']['output']>;
};

export type InternationalPaymentApiPaymentCurrencyResponse = {
  readonly __typename?: 'international_payment_api_PaymentCurrencyResponse';
  readonly currencies: ReadonlyArray<InternationalPaymentApiPaymentCurrency>;
};

export type InternationalPaymentApiPaymentPurpose = {
  readonly __typename?: 'international_payment_api_PaymentPurpose';
  readonly documentationInfoText: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly title: Scalars['String']['output'];
};

export enum InternationalPaymentApiPaymentPurposeEnum {
  CreditLoan = 'CREDIT_LOAN',
  Disbursement = 'DISBURSEMENT',
  Invoice = 'INVOICE',
  Other = 'OTHER',
  PurchaseGoods = 'PURCHASE_GOODS',
  SalaryPayOut = 'SALARY_PAY_OUT',
}

export enum InternationalPaymentApiRecipientType {
  Business = 'BUSINESS',
  Private = 'PRIVATE',
}

export type InternationalPaymentApiUpdateDocumentationPaymentResponse = {
  readonly __typename?: 'international_payment_api_UpdateDocumentationPaymentResponse';
  readonly errorMessage?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export enum InternationalPaymentApiUpdateFieldsEnum {
  AccountId = 'ACCOUNT_ID',
  AccountNumber = 'ACCOUNT_NUMBER',
  Amount = 'AMOUNT',
  BicSwift = 'BIC_SWIFT',
  Currency = 'CURRENCY',
  Iban = 'IBAN',
  MessageToReceiver = 'MESSAGE_TO_RECEIVER',
  MessageToSender = 'MESSAGE_TO_SENDER',
  Purpose = 'PURPOSE',
  PurposeOther = 'PURPOSE_OTHER',
  RecipientAccountNumberType = 'RECIPIENT_ACCOUNT_NUMBER_TYPE',
  RecipientAddress = 'RECIPIENT_ADDRESS',
  RecipientName = 'RECIPIENT_NAME',
  RecipientType = 'RECIPIENT_TYPE',
}

export type InternationalPaymentApiUpdatePaymentRequest = {
  readonly accountID?: InputMaybe<Scalars['ID']['input']>;
  readonly accountNumber?: InputMaybe<Scalars['String']['input']>;
  readonly amount?: InputMaybe<Scalars['String']['input']>;
  readonly bicSwift?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<Scalars['String']['input']>;
  readonly iban?: InputMaybe<Scalars['String']['input']>;
  readonly messageToReceiver?: InputMaybe<Scalars['String']['input']>;
  readonly messageToSender?: InputMaybe<Scalars['String']['input']>;
  readonly purpose?: InputMaybe<InternationalPaymentApiPaymentPurposeEnum>;
  readonly purposeOther?: InputMaybe<Scalars['String']['input']>;
  readonly recipientAccountNumberType?: InputMaybe<InternationalPaymentApiAccountNumberType>;
  readonly recipientAddress?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']>
  >;
  readonly recipientName?: InputMaybe<Scalars['String']['input']>;
  readonly recipientType?: InputMaybe<InternationalPaymentApiRecipientType>;
  readonly updateFields?: InputMaybe<
    ReadonlyArray<InternationalPaymentApiUpdateFieldsEnum>
  >;
};

export type InternationalPaymentApiUpdatePaymentResponse = {
  readonly __typename?: 'international_payment_api_UpdatePaymentResponse';
  readonly payment: InternationalPaymentApiPayment;
};

export type InternationalPaymentApiUploadDocumentationPaymentResponse = {
  readonly __typename?: 'international_payment_api_UploadDocumentationPaymentResponse';
  readonly uploadUrl: Scalars['String']['output'];
};

export type InternationalPaymentApiUploadDocumentationRequest = {
  readonly fileName?: InputMaybe<Scalars['String']['input']>;
};

export type NemkontoAcceptResponse = {
  readonly __typename?: 'nemkonto_AcceptResponse';
  /** Error message if Success is false */
  readonly error?: Maybe<Scalars['String']['output']>;
  /** Whether the request went well or not */
  readonly success: Scalars['Boolean']['output'];
};

export type NemkontoGenerationResponse = {
  readonly __typename?: 'nemkonto_GenerationResponse';
  /** Agreement ID */
  readonly agreementId?: Maybe<Scalars['String']['output']>;
  /** Error message if Success is false */
  readonly error?: Maybe<Scalars['String']['output']>;
  /** Url to the generated pdf */
  readonly fileUrl?: Maybe<Scalars['String']['output']>;
  /** Name of the file */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Whether the request went well or not */
  readonly success: Scalars['Boolean']['output'];
};

export type NemkontoStatus = {
  readonly __typename?: 'nemkonto_Status';
  /** Set if there is currently an active nemkonto account */
  readonly activeAccountId?: Maybe<Scalars['String']['output']>;
  /** ISO 8601 Timestamp */
  readonly activeAt?: Maybe<Scalars['String']['output']>;
  /** Set if there is currently an order nemkonto order for an account */
  readonly orderedAccountId?: Maybe<Scalars['String']['output']>;
  /** ISO 8601 Timestamp */
  readonly orderedAt?: Maybe<Scalars['String']['output']>;
};

export enum PremiumAction {
  ConfirmCancelPlan = 'CONFIRM_CANCEL_PLAN',
  ConfirmDowngradePlan = 'CONFIRM_DOWNGRADE_PLAN',
  ConfirmOrderPlan = 'CONFIRM_ORDER_PLAN',
  ConfirmResumePlan = 'CONFIRM_RESUME_PLAN',
  Ok = 'OK',
  SelectCancelPlan = 'SELECT_CANCEL_PLAN',
  SelectDowngradePlan = 'SELECT_DOWNGRADE_PLAN',
  SelectOrderPlan = 'SELECT_ORDER_PLAN',
}

export type PremiumActionButton = {
  readonly __typename?: 'premium_ActionButton';
  readonly action: PremiumAction;
  readonly text: Scalars['String']['output'];
};

export type PremiumActionCancelDialog = {
  readonly __typename?: 'premium_ActionCancelDialog';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly lostBenefitItems: ReadonlyArray<PremiumLostBenefitItem>;
  readonly title: Scalars['String']['output'];
};

export type PremiumActionCompletedDialog = {
  readonly __typename?: 'premium_ActionCompletedDialog';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
};

export type PremiumActionConfirmationDialog = {
  readonly __typename?: 'premium_ActionConfirmationDialog';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly info?: Maybe<PremiumInfoBox>;
  readonly priceUrl: Scalars['String']['output'];
  readonly subtitle: Scalars['String']['output'];
  readonly termsUrl: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumActionDowngradeDialog = {
  readonly __typename?: 'premium_ActionDowngradeDialog';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly link: Scalars['String']['output'];
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumBadge = {
  readonly __typename?: 'premium_Badge';
  readonly backgroundColor: Scalars['String']['output'];
  readonly iconUrl: Scalars['String']['output'];
  readonly text: Scalars['String']['output'];
  readonly textColor: Scalars['String']['output'];
};

export type PremiumBannerItem = {
  readonly __typename?: 'premium_BannerItem';
  readonly iconUrl: Scalars['String']['output'];
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly type: PremiumBannerType;
};

export enum PremiumBannerType {
  PromoCode = 'PROMO_CODE',
  Upgrade = 'UPGRADE',
}

export type PremiumBenefit = {
  readonly __typename?: 'premium_Benefit';
  readonly benefitId: Scalars['String']['output'];
  readonly info: Scalars['String']['output'];
  readonly shortInfo: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type PremiumBenefitV2 = {
  readonly __typename?: 'premium_BenefitV2';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly iconUrl: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumBenefitsGroup =
  | PremiumBenefitsGroupImageWithDropdown
  | PremiumBenefitsGroupSimple;

export type PremiumBenefitsGroupImageWithDropdown = {
  readonly __typename?: 'premium_BenefitsGroupImageWithDropdown';
  readonly benefits: ReadonlyArray<PremiumBenefitV2>;
  readonly headerTitle?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumBenefitsGroupSimple = {
  readonly __typename?: 'premium_BenefitsGroupSimple';
  readonly benefits: ReadonlyArray<PremiumBenefitV2>;
  readonly headerTitle?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
};

export type PremiumCancelPlanV2Response = {
  readonly __typename?: 'premium_CancelPlanV2Response';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumConfirmInfo = {
  readonly __typename?: 'premium_ConfirmInfo';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly bannerItems: ReadonlyArray<PremiumBannerItem>;
  readonly detailItems: ReadonlyArray<PremiumDetailItem>;
  readonly planId: PremiumPlanId;
  readonly priceUrl: Scalars['String']['output'];
  readonly promocode?: Maybe<Scalars['String']['output']>;
  readonly subtitle: Scalars['String']['output'];
  readonly termsUrl: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumDetailItem = {
  readonly __typename?: 'premium_DetailItem';
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export enum PremiumFrequency {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type PremiumGetPlansCompareLinkResponse = {
  readonly __typename?: 'premium_GetPlansCompareLinkResponse';
  readonly link: Scalars['String']['output'];
};

export type PremiumGetPlansV2Response = {
  readonly __typename?: 'premium_GetPlansV2Response';
  readonly plans: ReadonlyArray<PremiumPlanV2>;
};

export type PremiumInfoBox = {
  readonly __typename?: 'premium_InfoBox';
  readonly iconUrl: Scalars['String']['output'];
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumLostBenefitItem = {
  readonly __typename?: 'premium_LostBenefitItem';
  readonly iconUrl: Scalars['String']['output'];
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumOrderPlanResponse = {
  readonly __typename?: 'premium_OrderPlanResponse';
  readonly errorMessage: Scalars['String']['output'];
  readonly successful: Scalars['Boolean']['output'];
};

export type PremiumOrderPlanV2Response = {
  readonly __typename?: 'premium_OrderPlanV2Response';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly subtitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type PremiumPlanDetails = {
  readonly __typename?: 'premium_PlanDetails';
  readonly benefits: ReadonlyArray<PremiumBenefit>;
  readonly description: Scalars['String']['output'];
  readonly expiresAt?: Maybe<Scalars['String']['output']>;
  readonly nextPaymentDate?: Maybe<Scalars['String']['output']>;
  readonly planId: PremiumPlanId;
  readonly priceAmountMonthly: Scalars['Float']['output'];
  readonly priceAmountYearly: Scalars['Float']['output'];
  readonly priceCurrency: Scalars['String']['output'];
  readonly status: PremiumPlanStatus;
  readonly terms: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly type: PremiumPlanType;
};

export enum PremiumPlanId {
  DkEssential = 'DK_ESSENTIAL',
  DkGrow = 'DK_GROW',
  DkLimitless = 'DK_LIMITLESS',
  DkSimple = 'DK_SIMPLE',
  DkSolo = 'DK_SOLO',
  NoSolo = 'NO_SOLO',
  Unknown = 'UNKNOWN',
}

export enum PremiumPlanState {
  Active = 'Active',
  Available = 'Available',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
  Pending = 'Pending',
}

export enum PremiumPlanStatus {
  Active = 'ACTIVE',
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED',
}

export enum PremiumPlanType {
  Essential = 'ESSENTIAL',
  Grow = 'GROW',
  Limitless = 'LIMITLESS',
  Simple = 'SIMPLE',
  Solo = 'SOLO',
}

export type PremiumPlanV2 = {
  readonly __typename?: 'premium_PlanV2';
  readonly actions: ReadonlyArray<PremiumActionButton>;
  readonly badge?: Maybe<PremiumBadge>;
  readonly benefitsGroups: ReadonlyArray<PremiumBenefitsGroup>;
  readonly downgradeConfirmation?: Maybe<PremiumActionDowngradeDialog>;
  readonly headerColor: Scalars['String']['output'];
  readonly id: PremiumPlanId;
  readonly nextPaymentDate?: Maybe<Scalars['String']['output']>;
  readonly priceOptions: ReadonlyArray<PremiumPriceOption>;
  readonly state: PremiumPlanState;
  readonly subtitle: Scalars['String']['output'];
  readonly subtitleTextColor: Scalars['String']['output'];
  readonly terminationDate?: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
  readonly titleTextColor: Scalars['String']['output'];
  readonly type: PremiumPlanType;
};

export type PremiumPriceOption = {
  readonly __typename?: 'premium_PriceOption';
  readonly amount: Scalars['String']['output'];
  readonly amountMonthly: Scalars['String']['output'];
  readonly amountYearly: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly frequency: PremiumFrequency;
  readonly selected: Scalars['Boolean']['output'];
};

export type TransactionSearchSearchTransactionsInput = {
  readonly accountIds: ReadonlyArray<Scalars['String']['input']>;
  readonly from?: InputMaybe<Scalars['String']['input']>;
  readonly query: Scalars['String']['input'];
  readonly to?: InputMaybe<Scalars['String']['input']>;
};

export type TransferFeePaymentFeeInput = {
  readonly amount: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly fromAccountId: Scalars['String']['input'];
};

export type TransferFeeStandingOrderFeeInput = {
  readonly amount: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly fromAccountId: Scalars['String']['input'];
  readonly toAccountNumber: Scalars['String']['input'];
};

export type TransferFeeTransferFeeInput = {
  readonly amount: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly fromAccountId: Scalars['String']['input'];
  readonly toAccountNumber: Scalars['String']['input'];
};

export type TransferFeeTransferFeeResponse = {
  readonly __typename?: 'transfer_fee_TransferFeeResponse';
  readonly amount: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly originalAmount: Scalars['String']['output'];
};

export type VismaEaccountingAccount = {
  readonly __typename?: 'visma_eaccounting_Account';
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type VismaEaccountingAccountMapping = {
  readonly __typename?: 'visma_eaccounting_AccountMapping';
  readonly from: Scalars['ID']['output'];
  readonly to: Scalars['ID']['output'];
};

export type VismaEaccountingAccountMappingInput = {
  readonly from: Scalars['ID']['input'];
  readonly to: Scalars['ID']['input'];
};

export type VismaEaccountingCategory = {
  readonly __typename?: 'visma_eaccounting_Category';
  readonly accounts: ReadonlyArray<VismaEaccountingAccount>;
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['String']['output'];
  readonly sort: Scalars['Int']['output'];
  readonly title: Scalars['String']['output'];
};

export type VismaEaccountingConnectInput = {
  readonly code: Scalars['String']['input'];
};

export type VismaEaccountingGetAccountMapResponse = {
  readonly __typename?: 'visma_eaccounting_GetAccountMapResponse';
  readonly accountMap: ReadonlyArray<VismaEaccountingAccountMapping>;
};

export type VismaEaccountingGetAccountsResponse = {
  readonly __typename?: 'visma_eaccounting_GetAccountsResponse';
  readonly categories: ReadonlyArray<VismaEaccountingCategory>;
};

export type VismaEaccountingGetInstallationResponse = {
  readonly __typename?: 'visma_eaccounting_GetInstallationResponse';
  readonly url: Scalars['String']['output'];
};

export type VismaEaccountingGetIntegrationResponse = {
  readonly __typename?: 'visma_eaccounting_GetIntegrationResponse';
  readonly integration?: Maybe<VismaEaccountingIntegration>;
};

export type VismaEaccountingIntegration = {
  readonly __typename?: 'visma_eaccounting_Integration';
  readonly status: VismaEaccountingStatus;
};

export type VismaEaccountingSetAccountMapInput = {
  readonly accountMap: ReadonlyArray<VismaEaccountingAccountMappingInput>;
};

export enum VismaEaccountingStatus {
  Active = 'Active',
  Connected = 'Connected',
  None = 'None',
}

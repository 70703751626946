import { useBelow } from '@lunarway/matter-web';

export type AppShellMode = 'small' | 'large';

export const useAppShellMode = (): AppShellMode => {
  const isSmall = useBelow('md');

  if (isSmall) {
    return 'small';
  }

  return 'large';
};

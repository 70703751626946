import { NextPage } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { SessionProvider } from 'next-auth/react';
import { usePageViews, event } from 'nextjs-google-analytics';
import getConfig from 'next/config';
import Head from 'next/head';
import type { Session } from 'next-auth';
import '_src/framework/styles/fonts.css';
import '_src/framework/styles/reset.css';
import 'normalize.css';

import { ThemeProvider } from '_src/app/theme';
import { GoogleAnalytics, VercelAnalytics } from '_src/contexts/analytics';
import { MockProvider } from '_src/contexts/mock/MockProvider';
import { AppLayout } from '_src/app/app_shell/ui/shell/AppLayout';
import { Layout as LayoutType } from '_src/layout/Layout';
import { LayoutBlocker } from '_src/layout/LayoutBlocker';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: () => LayoutType;
};

export type NextPagePropsWithSession = {
  session: Session;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: NextPagePropsWithSession;
};

// NextJs performance metric.
// see: https://nextjs.org/docs/advanced-features/measuring-performance
export const reportWebVitals = ({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) => {
  event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
};

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  usePageViews();
  const { publicRuntimeConfig } = getConfig();
  const Layout = Component.getLayout?.() ?? AppLayout;

  return (
    <>
      <Head>
        <title>Lunar Business</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <LayoutBlocker>
        <MockProvider>
          <SessionProvider session={session}>
            <ThemeProvider>
              <Layout>
                <VercelAnalytics />
                <GoogleAnalytics nonce={publicRuntimeConfig.nonce} />
                <Component {...pageProps} />
              </Layout>
            </ThemeProvider>
          </SessionProvider>
        </MockProvider>
      </LayoutBlocker>
    </>
  );
};

export default appWithTranslation(App);

import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type GetStorageItemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetStorageItemQuery = {
  readonly __typename?: 'Query';
  readonly response?: {
    readonly __typename?: 'StorageItem';
    readonly id: string;
    readonly value: string;
  } | null;
};

export type SetStorageItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  value: Types.Scalars['String']['input'];
}>;

export type SetStorageItemMutation = {
  readonly __typename?: 'Mutation';
  readonly response: {
    readonly __typename?: 'StorageItem';
    readonly id: string;
    readonly value: string;
  };
};

export const GetStorageItemDocument = gql`
  query GetStorageItem($id: ID!) {
    response: getStorageItem(id: $id) {
      id
      value
    }
  }
`;

/**
 * __useGetStorageItemQuery__
 *
 * To run a query within a React component, call `useGetStorageItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStorageItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStorageItemQuery,
    GetStorageItemQueryVariables
  > &
    (
      | { variables: GetStorageItemQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStorageItemQuery, GetStorageItemQueryVariables>(
    GetStorageItemDocument,
    options
  );
}
export function useGetStorageItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStorageItemQuery,
    GetStorageItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStorageItemQuery, GetStorageItemQueryVariables>(
    GetStorageItemDocument,
    options
  );
}
export function useGetStorageItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetStorageItemQuery,
    GetStorageItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetStorageItemQuery,
    GetStorageItemQueryVariables
  >(GetStorageItemDocument, options);
}
export type GetStorageItemQueryHookResult = ReturnType<
  typeof useGetStorageItemQuery
>;
export type GetStorageItemLazyQueryHookResult = ReturnType<
  typeof useGetStorageItemLazyQuery
>;
export type GetStorageItemSuspenseQueryHookResult = ReturnType<
  typeof useGetStorageItemSuspenseQuery
>;
export type GetStorageItemQueryResult = Apollo.QueryResult<
  GetStorageItemQuery,
  GetStorageItemQueryVariables
>;
export const SetStorageItemDocument = gql`
  mutation SetStorageItem($id: ID!, $value: String!) {
    response: setStorageItem(id: $id, value: $value) {
      id
      value
    }
  }
`;
export type SetStorageItemMutationFn = Apollo.MutationFunction<
  SetStorageItemMutation,
  SetStorageItemMutationVariables
>;

/**
 * __useSetStorageItemMutation__
 *
 * To run a mutation, you first call `useSetStorageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStorageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStorageItemMutation, { data, loading, error }] = useSetStorageItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetStorageItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStorageItemMutation,
    SetStorageItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetStorageItemMutation,
    SetStorageItemMutationVariables
  >(SetStorageItemDocument, options);
}
export type SetStorageItemMutationHookResult = ReturnType<
  typeof useSetStorageItemMutation
>;
export type SetStorageItemMutationResult =
  Apollo.MutationResult<SetStorageItemMutation>;
export type SetStorageItemMutationOptions = Apollo.BaseMutationOptions<
  SetStorageItemMutation,
  SetStorageItemMutationVariables
>;
export const namedOperations = {
  Query: {
    GetStorageItem: 'GetStorageItem',
  },
  Mutation: {
    SetStorageItem: 'SetStorageItem',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStorageItemQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { getStorageItem }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetStorageItemQuery = (
  resolver: GraphQLResponseResolver<
    GetStorageItemQuery,
    GetStorageItemQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<GetStorageItemQuery, GetStorageItemQueryVariables>(
    'GetStorageItem',
    resolver,
    options
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSetStorageItemMutation(
 *   ({ query, variables }) => {
 *     const { id, value } = variables;
 *     return HttpResponse.json({
 *       data: { setStorageItem }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSetStorageItemMutation = (
  resolver: GraphQLResponseResolver<
    SetStorageItemMutation,
    SetStorageItemMutationVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.mutation<SetStorageItemMutation, SetStorageItemMutationVariables>(
    'SetStorageItem',
    resolver,
    options
  );

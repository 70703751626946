import { ReactNode } from 'react';
import { Flex } from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export type AppNavigationBarProps = {
  height: number;
  children: ReactNode;
};

export const AppNavigationBar = ({
  height,
  children,
}: AppNavigationBarProps) => (
  <StyledAppNavigationBar
    asChild
    justifyContent="space-between"
    alignItems="center"
    $height={height}
  >
    <header>{children}</header>
  </StyledAppNavigationBar>
);

const StyledAppNavigationBar = styled(Flex)<{ $height: number }>(
  ({ theme, $height }) => css`
    height: ${$height}px;
    padding: 0 ${theme.tokens.spaces[200]};
    border-block-end: 1px solid ${theme.surfaceSecondary};
    background: ${theme.backgroundPrimary};
  `
);

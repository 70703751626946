import { createContext, ReactNode, useContext, useMemo } from 'react';

import {
  FeatureFragment,
  useUserFeatureListQuery,
} from '_src/app/feature_toggle/data/__codegen__/UserFeatureList';

type ContextProps = {
  features: ReadonlyArray<FeatureFragment>;
  isLoading: boolean;
};

const FeatureContext = createContext<ContextProps>({
  features: [],
  isLoading: true,
});

type FeatureProviderProps = {
  children: ReactNode;
};

export const FeatureProvider = ({ children }: FeatureProviderProps) => {
  const { data, loading } = useUserFeatureListQuery();

  const featureList = useMemo(
    () => ({
      features: data?.features ?? [],
      isLoading: loading,
    }),
    [loading, data]
  );

  return loading || !data ? null : (
    <FeatureContext.Provider value={featureList}>
      {children}
    </FeatureContext.Provider>
  );
};

export function useFeatureList() {
  return useContext(FeatureContext);
}

import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useBelow,
} from '@lunarway/matter-web';
import { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'next-i18next';

import { useStorage } from '_src/app/storage/domain/use_storage';
import { withFeatureToggleAnyMatch } from '_src/app/feature_toggle';

import { DownloadAppHintDialog } from './DownloadAppHintDialog';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
const _DownloadAppHintPopover = () => {
  const { t } = useTranslation('hints');
  const { item, setItem, loading } = useStorage('download_app-hint');

  const [isOpen, setIsOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  const isBelowMD = useBelow('md');
  // prevent hint from competing with other content for user attention
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (!shouldRender || item === 'false' || loading) {
    return null;
  }

  return (
    <>
      <StyledPopover $isBelowMD={isBelowMD}>
        <StyledImage src="/assets/images/hints/app.png" />
        <StyledFlex flexDirection="column" gap={200}>
          <Flex flexDirection="column" gap={50}>
            <Text fontSize="titleS">{t('hints.download_app.title')}</Text>
            <Text>{t('hints.download_app.description')}</Text>
          </Flex>
          <ButtonGroup justify="start">
            <Button onClick={() => setIsOpen(true)}>
              {t('hints.download_app.read_more')}
            </Button>
            <Button variant="secondary" onClick={() => setItem('false')}>
              {t('hints.download_app.close')}
            </Button>
          </ButtonGroup>
        </StyledFlex>
      </StyledPopover>
      {isOpen && (
        <DownloadAppHintDialog isOpen onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

export const DownloadAppHintPopover = withFeatureToggleAnyMatch([
  'terra-hints',
])(_DownloadAppHintPopover);

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(15%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledPopover = styled.div<{
  $isBelowMD: boolean;
}>(
  ({ theme, $isBelowMD }) => css`
    position: fixed;
    bottom: ${theme.tokens.spaces[200]};
    left: calc(${theme.tokens.spaces[200]} + 240px);
    border-radius: ${theme.tokens.radii[150]};
    border: 1px solid ${theme.borderOpaque};
    background-color: ${theme.surfaceOverlayPrimary};
    max-width: 320px;
    animation: ${fadeInUp} 350ms cubic-bezier(0, 0.5, 0, 1) forwards;

    ${$isBelowMD &&
    css`
      left: ${theme.tokens.spaces[200]};
    `}
  `
);

const StyledImage = styled.img(
  ({ theme }) => css`
    width: 100%;
    border-top-left-radius: ${theme.tokens.radii[150]};
    border-top-right-radius: ${theme.tokens.radii[150]};
  `
);

const StyledFlex = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.tokens.spaces[200]};
  `
);

import { ApolloProvider } from '@apollo/client';
import { OverlayProvider, BreakpointsProvider } from '@lunarway/matter-web';

import { FeatureProvider } from '_src/app/feature_toggle';
import { FeatureRerouting } from '_src/app/feature_toggle/ui/FeatureRerouting';
import { NotifyProvider } from '_src/app/notification';
import { ErrorBoundary } from '_src/contexts/error/ErrorBoundary';
import { createGraphqlStitcherClient } from '_src/create_graphql_stitcher_client';
import { AppShell } from '_src/app/app_shell/ui/shell/AppShell';
import { IdleSessionTimeout } from '_src/app/idle_session_timeout/IdleSessionTimeout';
import { Layout } from '_src/layout/Layout';
import { SentryContextProvider } from '_src/contexts/log/sentry/SentryContextProvider';

const client = createGraphqlStitcherClient();

export const AppLayout: Layout = ({ children }) => (
  <ApolloProvider client={client}>
    <ErrorBoundary>
      <SentryContextProvider>
        <BreakpointsProvider>
          <NotifyProvider>
            <FeatureProvider>
              <FeatureRerouting>
                <OverlayProvider>
                  <IdleSessionTimeout />
                  <AppShell>{children}</AppShell>
                </OverlayProvider>
              </FeatureRerouting>
            </FeatureProvider>
          </NotifyProvider>
        </BreakpointsProvider>
      </SentryContextProvider>
    </ErrorBoundary>
  </ApolloProvider>
);

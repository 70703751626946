import { ReactNode, Component } from 'react';
import { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';

import { LogLevel } from '_src/__codegen__/types';

import {
  LoggerDocument,
  LoggerMutation,
  LoggerMutationVariables,
} from '../log/data/__codegen__/Logger';

type ErrorBoundaryProps = {
  client?: ApolloClient<object>;
  children?: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class PrivateErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !!error };
  }

  componentDidCatch(error: Error) {
    const { client } = this.props;

    if (client && error && error.message) {
      client.mutate<LoggerMutation, LoggerMutationVariables>({
        mutation: LoggerDocument,
        variables: {
          input: {
            message: error.message,
            level: LogLevel.Error,
          },
        },
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // TODO: create fallback UI
      return null;
    }

    return children;
  }
}

export const ErrorBoundary = withApollo(PrivateErrorBoundary);

import { ReactElement, ReactNode } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Text,
  focusRingWithBorder,
} from '@lunarway/matter-web';
import styled, { css } from 'styled-components';

export type AppNavigationCategoryProps = {
  id: string;
  icon: ReactElement;
  label: string;
  isOpen?: boolean;
  isActive?: boolean;
  children: ReactNode;
  onClick: (id: string) => void;
};

export const AppNavigationCategory = ({
  id,
  icon,
  label,
  isOpen,
  isActive,
  children,
  onClick,
}: AppNavigationCategoryProps) => (
  <>
    <StyledAppNavigationCategory
      asChild
      gap={100}
      tabIndex={0}
      alignItems="center"
      $isActive={isActive}
      data-test-id={id}
    >
      <button type="button" onClick={() => onClick(id)}>
        {icon}
        <StyledText whiteSpace="nowrap">{label}</StyledText>
        {isOpen ? (
          <StyledChevronUp size="small" variant="surfaceQuaternary" />
        ) : (
          <StyledChevronDown size="small" variant="surfaceQuaternary" />
        )}
      </button>
    </StyledAppNavigationCategory>
    {isOpen && (
      <Flex flexDirection="column" gap={100}>
        {children}
      </Flex>
    )}
  </>
);

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAppNavigationCategory = styled(Flex)<{
  $isActive?: boolean;
}>(
  ({ theme, $isActive }) => css`
    transition:
      background-color 150ms linear,
      transform 150ms cubic-bezier(0.65, 0.05, 0.36, 1);
    border: 1px solid transparent;
    color: currentColor;
    background-color: transparent;
    border-radius: ${theme.tokens.radii[150]};
    padding: ${theme.tokens.spaces[100]};
    cursor: pointer;

    ${$isActive &&
    css`
      background-color: ${theme.interaction.selected};
    `};

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${theme.interaction.hovered};
        transform: translateY(-1px);
      }
    }

    &:focus-visible {
      ${focusRingWithBorder(theme.surfacePrimary)}
    }
  `
);

const StyledChevronUp = styled(ChevronUpIcon)`
  margin-inline-start: auto;
`;

const StyledChevronDown = styled(ChevronDownIcon)`
  margin-inline-start: auto;
`;

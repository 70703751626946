import { useEffect, useRef, useState } from 'react';

import { useRequestAnimationFrame } from '_src/framework/utils/use_request_animation_frame';

export type UseCountdownOptions = {
  duration: number;
  cutoff?: 'hours' | 'minutes' | 'seconds';
};

export const useCountdown = ({
  duration,
  cutoff = 'minutes',
}: UseCountdownOptions) => {
  const startTime = useRef(performance.now());
  const [remainingTime, setRemainingTime] = useState(duration);

  useRequestAnimationFrame((update) => {
    const elapsedTime = window.performance.now() - startTime.current;
    const remainingTimeUpdate = Math.max(0, duration - elapsedTime);

    setRemainingTime(remainingTimeUpdate);
    update();
  });

  useEffect(() => {
    startTime.current = performance.now();
    setRemainingTime(duration);
  }, [duration]);

  return {
    readable: ToHumandReadable(remainingTime, cutoff),
    progress: 1 - remainingTime / duration,
    remaining: remainingTime,
  };
};
const ToHumandReadable = (
  num: number,
  cutoff: 'hours' | 'minutes' | 'seconds'
) => {
  const hours = Math.floor((num / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((num / (1000 * 60)) % 60);
  const seconds = Math.floor((num / 1000) % 60);

  const showHours = cutoff === 'hours' || hours > 0;
  const showMinutes = cutoff === 'minutes' || showHours || minutes > 0;
  const showSeconds = cutoff === 'seconds' || showMinutes || seconds > 0;

  const hoursString = showHours ? `${hours}`.padStart(2, '0') : '';
  const minutesString = showMinutes ? `${minutes}`.padStart(2, '0') : '';
  const secondsString = showSeconds ? `${seconds}`.padStart(2, '0') : '';

  return [hoursString, minutesString, secondsString].filter(Boolean).join('.');
};

import { createContext, useContext } from 'react';

import { TerraTheme } from '_src/__codegen__/types';

const TerraThemeContext =
  // @ts-expect-error no default value
  createContext<[TerraTheme, (terraTheme: TerraTheme) => void]>();

export const TerraThemeProvider = TerraThemeContext.Provider;

export const useTerraTheme = () => useContext(TerraThemeContext);

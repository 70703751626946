import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type LoggerMutationVariables = Types.Exact<{
  input: Types.LogInput;
}>;

export type LoggerMutation = {
  readonly __typename?: 'Mutation';
  readonly log: boolean;
};

export const LoggerDocument = gql`
  mutation Logger($input: LogInput!) {
    log(input: $input)
  }
`;
export type LoggerMutationFn = Apollo.MutationFunction<
  LoggerMutation,
  LoggerMutationVariables
>;

/**
 * __useLoggerMutation__
 *
 * To run a mutation, you first call `useLoggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loggerMutation, { data, loading, error }] = useLoggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoggerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoggerMutation,
    LoggerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoggerMutation, LoggerMutationVariables>(
    LoggerDocument,
    options
  );
}
export type LoggerMutationHookResult = ReturnType<typeof useLoggerMutation>;
export type LoggerMutationResult = Apollo.MutationResult<LoggerMutation>;
export type LoggerMutationOptions = Apollo.BaseMutationOptions<
  LoggerMutation,
  LoggerMutationVariables
>;
export const namedOperations = {
  Mutation: {
    Logger: 'Logger',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoggerMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { log }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLoggerMutation = (
  resolver: GraphQLResponseResolver<LoggerMutation, LoggerMutationVariables>,
  options?: RequestHandlerOptions
) =>
  graphql.mutation<LoggerMutation, LoggerMutationVariables>(
    'Logger',
    resolver,
    options
  );

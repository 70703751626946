import { Key } from 'react';
import {
  CogIcon,
  Flex,
  LogOutIcon,
  MenuItem,
  MenuList,
  Popover,
  QuestionmarkCircleIcon,
  usePopover,
} from '@lunarway/matter-web';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { signOut } from 'next-auth/react';

import {
  useFeatureToggle,
  useFeatureToggleAnyMatch,
} from '_src/app/feature_toggle';
import { useGetCurrentUserQuery } from '_src/app/profile_menu/data/__codegen__/GetCurrentUser';
import { useGetRelatedUsers } from '_src/app/user_switch/data/useGetRelatedUsers';
import { UserSwitchMenuItem } from '_src/app/user_switch/ui/UserSwitchMenuItem';

import { ProfileMenuButton } from './ProfileMenuButton';
import { ProfileMenuItem } from './ProfileMenuItem';

enum Action {
  Subscription = 'SUBSCRIPTION',
  Help = 'HELP',
  Logout = 'LOGOUT',
  Settings = 'SETTINGS',
  UserSwitch = 'USER_SWITCH',
}

export const ProfileMenu = () => {
  const router = useRouter();
  const settingsIsEnabled = useFeatureToggleAnyMatch([
    'terra-settings-language',
    'terra-settings-region',
    'terra-settings-theme',
    'terra-subscription_tiers',
  ]);
  const userSwitchIsEnabled = useFeatureToggle('terra-user-switch');
  const { t } = useTranslation('app_navigation');
  const { data } = useGetCurrentUserQuery();
  const { popoverState, popoverProps, popoverTriggerProps, popoverTriggerRef } =
    usePopover<HTMLDivElement>();
  const user = data?.response.Content?.CurrentUser;
  const { users } = useGetRelatedUsers();
  const hasMoreUsers = users.length > 1;
  const canSwitchUsers = userSwitchIsEnabled && hasMoreUsers;

  const handleAction = async (action: Key) => {
    switch (action as Action) {
      case Action.Subscription:
        await router.push('/subscription');
        break;
      case Action.Help:
        window.location.assign(t('app_navigation.help_link'));
        break;
      case Action.Logout:
        await signOut();
        break;
      case Action.Settings:
        await router.push('/profile');
        break;
      default:
      // Do nothing
    }

    popoverState.close();
  };

  const menuItemHelp = [
    <MenuItem key={Action.Help} textValue={t('app_navigation.items.help')}>
      <ProfileMenuItem
        icon={<QuestionmarkCircleIcon />}
        label={t('app_navigation.items.help')}
        data-test-id="app-navigation-help"
      />
    </MenuItem>,
  ];

  const menuItemLogOut = [
    <MenuItem key={Action.Logout} textValue={t('app_navigation.log_out')}>
      <ProfileMenuItem
        icon={<LogOutIcon />}
        label={t('app_navigation.log_out')}
        data-test-id="app-navigation-logout"
      />
    </MenuItem>,
  ];

  const menuUserSwitch = [
    <>
      {canSwitchUsers && (
        <>
          <UserSwitchMenuItem
            users={users}
            label={t('app_navigation.items.user_switch')}
            data-test-id="app-navigation-user-switch"
          />
          <MenuList aria-label="Logout Menu" onAction={handleAction}>
            {...menuItemLogOut}
          </MenuList>
        </>
      )}
    </>,
  ];

  const menuItems = [
    ...(settingsIsEnabled
      ? [
          <MenuItem
            key={Action.Settings}
            textValue={t('app_navigation.items.settings')}
          >
            <ProfileMenuItem
              icon={<CogIcon />}
              label={t('app_navigation.items.settings')}
              data-test-id="app-navigation-settings"
            />
          </MenuItem>,
        ]
      : []),
    ...menuItemHelp,
    ...(!canSwitchUsers ? [...menuItemLogOut] : []),
  ];

  return (
    <>
      <ProfileMenuButton
        ref={popoverTriggerRef}
        user={user}
        alignSelf="flex-start"
        isOpen={popoverState.isOpen}
        data-test-id="app-navigation-profile-preview-button"
        {...popoverTriggerProps}
      />
      <Popover
        anchorRef={popoverTriggerRef}
        shouldFocusFirstOnMount={false}
        placementBlock="start"
        placementInline="start"
        style={{ width: popoverTriggerRef.current?.offsetWidth }}
        {...popoverProps}
      >
        <Flex flexDirection="column">
          <MenuList
            aria-label="Profile Menu"
            autoFocus="first"
            onAction={handleAction}
          >
            {menuItems}
          </MenuList>
          {...menuUserSwitch}
        </Flex>
      </Popover>
    </>
  );
};

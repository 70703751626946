import * as Types from '../../../../__codegen__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw';
const defaultOptions = {} as const;
export type GetCurrentUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserQuery = {
  readonly __typename?: 'Query';
  readonly response: {
    readonly __typename?: 'UserRelationResponse';
    readonly Success: boolean;
    readonly Error?: string | null;
    readonly Content?: {
      readonly __typename?: 'UserRelationContent';
      readonly CurrentUser: {
        readonly __typename?: 'CurrentUser';
        readonly ID: string;
        readonly AvatarURL: string;
        readonly DisplayName: string;
        readonly ShortName: string;
      };
    } | null;
  };
};

export type UserFragment = {
  readonly __typename?: 'CurrentUser';
  readonly ID: string;
  readonly AvatarURL: string;
  readonly DisplayName: string;
  readonly ShortName: string;
};

export const UserFragmentDoc = gql`
  fragment User on CurrentUser {
    ID
    AvatarURL
    DisplayName
    ShortName
  }
`;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    response: terraGetUserRelation {
      Content {
        CurrentUser {
          ...User
        }
      }
      Success
      Error
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const namedOperations = {
  Query: {
    GetCurrentUser: 'GetCurrentUser',
  },
  Fragment: {
    User: 'User',
  },
};

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCurrentUserQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { terraGetUserRelation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetCurrentUserQuery = (
  resolver: GraphQLResponseResolver<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
  options?: RequestHandlerOptions
) =>
  graphql.query<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    'GetCurrentUser',
    resolver,
    options
  );

import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';

import { createChallenge } from '_src/app/user_switch/domain/challenge';

interface SwitchUserResponse {
  login_hint: string;
  login_params: string;
}

export const useSwitchUser = () => {
  const router = useRouter();

  return async (userId: string) => {
    const callbackUrl = (router.query?.callbackUrl as string) || '/home';

    const challenge = await createChallenge();

    const response = await fetch('/api/mediator', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        user_relation_id: userId,
        flow_challenge: challenge.code_challenge,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to change user');
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { login_hint, login_params } =
      (await response.json()) as SwitchUserResponse;

    const hint = login_hint || '';

    signIn(
      'lunar-eid',
      {
        callbackUrl,
      },
      `login_hint=${hint}&${login_params}&flow_verifier=${challenge.code_verifier}`
    );
  };
};
